import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAuth } from "../context/AuthContext";
import { useLoc } from "../context/LocContext";
import PasswordField from "./PasswordField";

const apiUrl = process.env.IDA_API_URL;

export default function SignUpForm() {
  const [submitting, setSubmitting] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [cid, setCid] = useState("");
  const [oid, setOid] = useState("");
  const [bcid, setBcid] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const loc = useLoc();

  useEffect(() => {
    const cid = searchParams.get("cid");
    const oid = searchParams.get("oid");
    const bcid = searchParams.get("bcid");

    setCid(cid);
    setOid(oid);
    setBcid(bcid);

    if (!cid || !oid || !bcid) {
      navigate("/");
      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        `${apiUrl}/customer/check?memberId=${bcid}&tenantId=${loc.tenantId}`
      );
      return await res.json();
    };

    fetchData().then((json) => {
      if (json.object === true) {
        navigate("/signin");
        return;
      }
    });
  }, [searchParams, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (!password || !passwordCheck) {
      auth.addNotification(t("messages.required-fields-empty"));
      setSubmitting(false);
      return;
    }

    if (password !== passwordCheck) {
      auth.addNotification(t("messages.passwords-match-error"));
      setSubmitting(false);
      return;
    }

    await idaSignUp();
  };

  async function idaSignUp() {
    try {
      const res = await fetch(`${apiUrl}/customer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cid: cid,
          oid: oid,
          bcid: bcid,
          password: password,
          tenantId: loc.tenantId,
        }),
      });
      const json = await res.json();

      if (json.success === false) {
        console.log(json.object);
        auth.addNotification(t("messages.cognitopassworddidnotconform"));
      }

      if (json.success === true) {
        // await cognitoSignIn(json.object.email, password);
        navigate("/activate");
      }
    } catch (error) {
      console.log("Error:", error);
      auth.addNotification(error.message);
    }

    setSubmitting(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <fieldset className="mb-6">
        <label className="block mb-2.5 font-interbold">
          {t("password-create")} <span className="text-red-600">*</span>
        </label>
        <PasswordField
          name="password"
          placeholder={t("password-create-placeholder")}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </fieldset>
      <fieldset className="mb-5">
        <label className="block mb-2.5 font-interbold">
          {t("password-verify")} <span className="text-red-600">*</span>
        </label>
        <PasswordField
          name="password-check"
          placeholder={t("password-verify-placeholder")}
          value={passwordCheck}
          onChange={(e) => {
            setPasswordCheck(e.target.value);
          }}
        />
      </fieldset>
      <button
        type="submit"
        className="block bg-dark-blue rounded-full text-white font-interbold py-3 px-10 mt-10 mx-auto uppercase disabled:cursor-not-allowed disabled:bg-gray-500"
        disabled={submitting}
      >
        {t("signup-button")}
      </button>
    </form>
  );
}
