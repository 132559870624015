import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Header from "../components/Header";
import LoaderIcon from "../components/LoaderIcon";
import Ws from "../components/Ws";
import { useAuth } from "../context/AuthContext";
import { RequireAuth } from "../services/auth";
import { getDataFromIda } from "../services/ida";

export default function DebugPage() {
  const [ida, setIda] = useState();
  const { t } = useTranslation();
  const controllerRef = useRef();
  const auth = useAuth();
  const user = auth.user;

  document.title = t("homepage-title");

  useEffect(() => {
    if (!user) {
      return null;
    }

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    getDataFromIda(user, controllerRef)
      .then((data) => {
        setIda(data);
      })
      .catch((error) => {
        auth.addNotification(error.message);
      });

    return () => controller.abort();
  }, [user]);

  return (
    <RequireAuth>
      <div className="bg-white min-h-screen">
        <Header title={`Welcome, ${user?.customerUserName}`}></Header>
        <Ws />
        <div className="p-10">
          {!user ? <p>{t("homepage-no-auth")}</p> : ""}
          {ida && (
            <>
              <h3 className="text-md mb-3 font-bold">{t("description.ida")}</h3>
              <pre
                className="text-xs"
                style={{
                  maxWidth: "fit-content",
                  whiteSpace: "pre-wrap",
                  wordWrap: "anywhere",
                }}
              >
                {JSON.stringify(ida, null, 2)}
              </pre>
            </>
          )}
          {!ida ? <LoaderIcon theme="dark" size="small" /> : null}

          <div className="py-10">
            <h3 className="text-md mb-3 font-bold">User object</h3>
            <pre
              className="text-xs"
              style={{
                maxWidth: "fit-content",
                whiteSpace: "pre-wrap",
                wordWrap: "anywhere",
              }}
            >
              {JSON.stringify(user, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </RequireAuth>
  );
}
