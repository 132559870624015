import React, { useEffect, useState } from "react";

import { useAuth } from "../context/AuthContext";
import { useWsConnection } from "../context/WsContext";

export default function Ws() {
  const [log, setLog] = useState([]);
  const auth = useAuth();
  const wsConnection = useWsConnection();
  const user = auth.user;

  useEffect(() => {
    if (!user || wsConnection.connectionId === null) {
      return;
    }

    const handler = (message) => updateLog(message);

    wsConnection.on("Customer_Todo", handler);
    wsConnection.on("Customer_Message", handler);
    wsConnection.on("Customer_Document", handler);
    wsConnection.on("Customer_Image", handler);
    wsConnection.on("Customer_Todo", handler);

    //clean up function
    return () => {
      wsConnection.off("Customer_Todo", handler);
      wsConnection.off("Customer_Message", handler);
      wsConnection.off("Customer_Document", handler);
      wsConnection.off("Customer_Image", handler);
      wsConnection.off("Customer_Todo", handler);
    };
  }, []);

  const updateLog = (item) => {
    setLog((prev) => [...prev, item]);
  };

  const items = log.map((item, index) => (
    <div key={index}>
      <p>{JSON.stringify(item)}</p>
    </div>
  ));

  if (items.length === 0) {
    return null;
  }

  return (
    <div className="p-10 pb-0">
      <h3 className="text-md mb-3 font-bold">Websocket</h3>
      {items}
    </div>
  );
}
