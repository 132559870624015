import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.parcel2";

export default function PdfPreview({ file }) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      {file && (
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              className="mb-10 drop-shadow-xl bg-green-50"
            />
          ))}
        </Document>
      )}
    </>
  );
}
