import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Messenger from "./Messenger";

export default function MessengerDesktop({ contRef, shareRef, meetingRef }) {
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const { t } = useTranslation();
  const title = t("communication-title");

  useEffect(() => {
    const timeout = setTimeout(calculateHeightForMessages, 50);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    calculateHeightForMessages();
  }, [window.innerHeight]);

  function calculateHeightForMessages() {
    let h = 0;
    if (contRef.current) {
      let containerStyle = window.getComputedStyle(contRef.current);
      h += parseInt(containerStyle.getPropertyValue("padding-top"));
      h += parseInt(containerStyle.getPropertyValue("padding-bottom"));
    }

    if (shareRef.current) {
      h += parseInt(shareRef.current.clientHeight);
    }

    if (meetingRef.current) {
      h += parseInt(meetingRef.current.clientHeight);
    }

    setWrapperHeight(h);
  }

  return (
    <div
      className="flex flex-col mb-8"
      style={{ height: `calc(100vh - 32px - ${wrapperHeight}px)` }}
    >
      <h3 className="text-xs uppercase text-dark-blue font-interbold mb-4">
        {title}
      </h3>
      <div
        className="flex flex-col justify-between shadow-default bg-white rounded-lg"
        style={{ height: `calc(100vh - 64px - ${wrapperHeight}px)` }}
      >
        <Messenger />
      </div>
    </div>
  );
}
