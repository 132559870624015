import React from "react";

import HeaderAuth from "./HeaderAuth";
import PreAuthContent from "./PreAuthContent";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <main className="relative flex-grow bg-dark-blue bg-auth bg-cover bg-no-repeat bg-center">
          <HeaderAuth />
          <PreAuthContent title="Something went wrong 😭">
            <div>
              <div className="mb-2">
                {this.state.error && this.state.error.toString()}
              </div>
              {process.env.NODE_ENV !== "production" && (
                <details style={{ whiteSpace: "pre-wrap", fontSize: "12px" }}>
                  {this.state.errorInfo.componentStack}
                </details>
              )}
            </div>
          </PreAuthContent>
        </main>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
