import React, { forwardRef } from "react";

import ToDoItem from "./ToDoItem";

let doneIcon = new URL(
  "../assets/images/todo-done-triangle.svg",
  import.meta.url
);

const ToDoS = ({ title, isDone, items, toggleCompleted }, ref) => {
  const itemsLength = items.length;
  let lineColor = "bg-light-pink";
  if (isDone) {
    lineColor = "bg-dark-blue";
  }
  const titleHtml = (
    <div className="relative text-xs uppercase text-dark-blue font-interbold mb-4 flex flex-row pl-[25] z-[2]">
      {isDone ? (
        <img src={doneIcon} width={14} height={14} className="mr-4" />
      ) : (
        <div className="h-[14] w-[14] bg-light-pink rounded-full mr-4" />
      )}
      <span>{title}</span>
    </div>
  );

  function sortItems(items) {
    return items.sort((a, b) => {
      if (isDone) {
        return a.apiOrder > b.apiOrder ? -1 : 1;
      } else {
        return a.apiOrder > b.apiOrder ? 1 : -1;
      }
    });
  }

  return (
    <>
      <div className="relative pb-4 last:pb-0">
        {isDone && (
          <div className="w-1 absolute bg-gradient-to-t from-light-pink to-dark-blue bottom-0 left-[30px] h-[30] z-[1]" />
        )}
        <div className={`w-1 h-full absolute ${lineColor} top-0 left-[30px]`} />
        {!isDone && titleHtml}
        {sortItems(items).map((item, index) => {
          return (
            <div key={item.id}>
              {isDone && itemsLength > 2 && index === itemsLength - 2 && (
                <div ref={ref} />
              )}
              <ToDoItem
                key={item.id}
                {...item}
                toggleCompleted={toggleCompleted}
              />
            </div>
          );
        })}
        {isDone && titleHtml}
      </div>
    </>
  );
};
const ToDoSection = forwardRef(ToDoS);

export default ToDoSection;
