import React from "react";
import { Link } from "react-router-dom";

let logo = new URL("../assets/images/logo-white.svg", import.meta.url);
let closeIcon = new URL("../assets/images/icon-close.svg", import.meta.url);

const ModalFullScreen = ({
  title,
  titleClass,
  showLogo,
  children,
  onClose,
}) => {
  let tClasses = "font-internormal text-white uppercase text-[12px]";
  if (titleClass) {
    tClasses = titleClass;
  }

  return (
    <div className="flex flex-col bg-dark-blue fixed w-full h-full z-50">
      <div className="flex items-center p-5">
        {showLogo && (
          <div className="flex justify-center">
            <Link to="/todo">
              <img
                src={logo}
                alt="Fenix"
                width="42"
                height="42"
                className="h-fit max-w-none"
              />
            </Link>
          </div>
        )}

        <div className="flex flex-grow items-center">
          <div className={`w-full ${tClasses}`}>{title}</div>
        </div>
        <div className="items-center">
          <img
            src={closeIcon}
            alt="close"
            width="22"
            height="22"
            className="h-fit max-w-none cursor-pointer"
            onClick={onClose}
          />
        </div>
      </div>
      <div className="flex-grow p-5 overflow-auto">{children}</div>
    </div>
  );
};

export default ModalFullScreen;
