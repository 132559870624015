import React from "react";

import LoaderIcon from "./LoaderIcon";

export default function LoaderScreen() {
  return (
    <div className="flex h-screen w-full justify-center items-center bg-dark-blue">
      <LoaderIcon />
    </div>
  );
}
