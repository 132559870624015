{
    "description": {
        "ida": "Data From Ida"
    },
    "messages": {
        "general": "Something went wrong.",
        "required-fields-empty": "Required fields cannot be empty!",
        "passwordcannotbeempty": "Required fields cannot be empty!",
        "passwords-match-error": "The passwords do not match. Try again.",
        "code-error": "Enter the code you received via email.",
        "incorrectusernameorpassword": "Invalid username or password.",
        "userdoesnotexist": "Invalid username or password.",
        "cognitopassworddidnotconform": "Invalid password. The password must consist of at least 8 characters, of which at least one is a number and one special character. Use both upper and lower case letters.",
        "1validationerrordetectedvalueatpasswordfailedtosatisfyconstraintmembermustsatisfyregularexpressionpattern^[\\s]+*[\\s]+$": "Invalid password. The password must consist of at least 8 characters, of which at least one is a number and one special character. Use both upper and lower case letters.",
        "passworddoesnotconformtopolicypasswordnotlongenough": "Invalid password. The password must consist of at least 8 characters, of which at least one is a number and one special character. Use both upper and lower case letters.",
        "passworddoesnotconformtopolicypasswordmusthaveuppercasecharacters": "Invalid password. The password must consist of at least 8 characters, of which at least one is a number and one special character. Use both upper and lower case letters.",
        "LimitExceededException": "The attempt limit has been exceeded, try again after some time.",
        "UserNotFoundException": "Invalid email address. It must be the same email address you entered for your account with us.",
        "NotAuthorizedException": "Invalid email address. It must be the same email address you entered for your account with us.",
        "duharintetillgångtillnågonbeställning": "You do not have access to any orders."
    },
    "homepage-title": "Home",
    "homepage-no-auth": "Sign In to view the content.",
    "signin-title": "Sign In",
    "signin-button": "Sign In",
    "signup-title": "Create User",
    "signup-button": "Create User",
    "reset-password-title": "Reset Password",
    "reset-password-button": "Reset Password",
    "reset-password-text": "Fill in your email address and we will help you.",
    "reset-password-title-success": "Reset Password",
    "reset-password-confirm-title": "Confirm Changing Password",
    "reset-password-confirm-code": "Code",
    "reset-password-confirm-code-placeholder": "Enter the code from email",
    "reset-password-confirm-button": "Confirm",
    "activate-title": "Activate Account",
    "activate-success": "Your account was successfully activated. You can sign in now!",
    "activate-desc": "Check your email for the activation link.",
    "email": "Email",
    "email-placeholder": "Enter your email",
    "shared-email-placeholder": "Enter email",
    "name": "Name",
    "first-name": "First name",
    "first-name-placeholder": "Enter first name",
    "last-name": "Last name",
    "last-name-placeholder": "Enter last name",
    "password": "Password",
    "password-placeholder": "Enter password",
    "password-create": "Create password",
    "password-create-placeholder": "Enter password",
    "password-verify": "Verify Password",
    "password-verify-placeholder": "Enter password",
    "submit": "Submit",
    "no-items": "No items to display",
    "sign-out": "Sign Out",
    "book-a-meeting": "Book a meeting",
    "forgot-password-title": "Forgot password?",
    "forgot-password-desc": "Check your email for the password reset link.",
    "forgot-password-success": "New password successfully set. You can sign in now!",
    "profile-title": "Profile",
    "profile-remove-button": "Remove Cognito User & Sign Out",
    "todo-menu-title": "To Do",
    "todo-title": "To Do",
    "todo-title-no-name": "To Do",
    "todo-description": "Some explanatory text describing the purpose and functionality of the To Do page.",
    "todo-done": "Done",
    "todo-this-week": "To do this week",
    "todo-next-week": "To do next week",
    "todo-later": "To do later",
    "enter-text": "Enter text",
    "bookmeeting-title": "Your team at fenix",
    "bookmeeting-title-prefix": "Book a meeting with {{name}}",
    "communication-title": "Communication",
    "messenger-no-messages": "YOU HAVE NO COMMUNICATION YET",
    "files-menu-title": "Files",
    "files-title": "Files",
    "files-description": "Some explanatory text describing the purpose and functionality of the Files page.",
    "images-menu-title": "Images",
    "images-title": "Images",
    "images-description": "Some explanatory text describing the purpose and functionality of the Images page.",
    "funeral-director": "Funeral Director",
    "send": "Send",
    "visit-memorial-site": "Visit site",
    "shared-with-title": "Shared With",
    "shared-success-title": "Shared With",
    "shared-success-text": "Request successfully sent",
    "invite": "Invite",
    "send-invite": "Send Invite",
    "show-more": "Show more",
    "show-less": "Show less",
    "and": "and",
    "you": "you",
    "more": "more",
    "cost": "Cost",
    "no-submit": {
        "default": "You can not submit or update information.",
        "messenger": "You can not submit or update information.",
        "bookmeeting": "You can not submit or update information.",
        "todo": "You can not submit or update information."
    },
    "no-order-access": "You do not have access to any order."
}