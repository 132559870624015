import React from "react";
import { useTranslation } from "react-i18next";

import DocumentList from "../components/DocumentList";
import Header from "../components/Header";
import { RequireAuth } from "../services/auth";

export default function FilesPage() {
  const { t } = useTranslation();
  document.title = t("files-title");

  return (
    <RequireAuth>
      <Header title={document.title} desc={t("files-description")}></Header>
      <div className="p-5 pt-8 sm:p-10">
        <DocumentList section="All documents" type={1} />
      </div>
    </RequireAuth>
  );
}
