import React from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../context/AuthContext";
import ActiveMenuLink from "./ActiveMenuLink";

let iconUser = new URL("../assets/images/icon-user.svg", import.meta.url);

export default function UserMenuItems({ itemClass }) {
  const auth = useAuth();
  const { t } = useTranslation();

  let menuItemClass = "";
  if (itemClass) {
    menuItemClass = itemClass;
  }

  return (
    <>
      <ActiveMenuLink
        to=""
        onClick={(e) => {
          e.preventDefault();
          auth.signOut();
        }}
        image={iconUser}
      >
        <span className={menuItemClass}>{t("sign-out")}</span>
      </ActiveMenuLink>
    </>
  );
}
