import React from "react";
import QRCode from "react-qr-code";

export default function QRC({ value }: { value: string }) {
  if (!value) {
    return null;
  }
  return (
    <div
      style={{
        height: "auto",
        maxWidth: 250,
        width: "100%",
        marginTop: "30px",
      }}
    >
      <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={value}
        viewBox={`0 0 256 256`}
      />
    </div>
  );
}
