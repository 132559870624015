import React from "react";
import { Outlet } from "react-router-dom";

import HeaderAuth from "./HeaderAuth";

export default function LayoutAuth() {
  return (
    <main className="relative flex-grow bg-dark-blue bg-auth bg-cover bg-no-repeat bg-center">
      <HeaderAuth />
      <Outlet />
    </main>
  );
}
