import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import HeaderAuth from "./HeaderAuth";
import PreAuthContent from "./PreAuthContent";

export default function NoPage() {
  let location = useLocation();

  return (
    <main className="relative flex-grow bg-dark-blue bg-auth bg-cover bg-no-repeat bg-center">
      <HeaderAuth />
      <PreAuthContent title="404">
        <div className="text-center">Page not found {location.pathname}</div>
      </PreAuthContent>
    </main>
  );
}
