import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import PreAuthContent from "../components/PreAuthContent";
import { useAuth } from "../context/AuthContext";
import Amplify from "../services/amplify";

export default function ForgotPasswordPage() {
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [successMsg, setSuccessMsg] = useState(null);
  const { t, i18n } = useTranslation();
  const auth = useAuth();

  document.title = t("reset-password-title");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (!email) {
      auth.addNotification(t("messages.required-fields-empty"));
      setSubmitting(false);
      return;
    }

    await forgotPassword(email);
  };

  async function forgotPassword(email) {
    try {
      const data = await Amplify.Auth.forgotPassword(email);

      if (data) {
        setSuccessMsg(true);
      }
    } catch (error) {
      console.log("Error:", JSON.stringify(error), error.message);
      auth.addNotification(t(`messages.${error.code}`));
    }

    setSubmitting(false);
  }

  return (
    <PreAuthContent
      title={
        successMsg
          ? t("reset-password-title-success")
          : t("reset-password-title")
      }
    >
      {successMsg ? (
        <p className="text-center">{t("forgot-password-desc")}</p>
      ) : (
        <>
          <p className="mb-5">{t("reset-password-text")}</p>
          <form onSubmit={handleSubmit}>
            <fieldset className="mb-5">
              <label className="block mb-2.5 font-interbold">
                {t("email")} <span className="text-red-600">*</span>
              </label>
              <input
                className="border-2 border-dark-blue rounded-full py-1.5 px-4 w-full placeholder:text-sm"
                type="email"
                name="email"
                id="email"
                placeholder={t("email-placeholder")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </fieldset>
            <button
              type="submit"
              className="block bg-dark-blue rounded-full text-white font-interbold py-3 px-10 mt-10 mx-auto uppercase disabled:cursor-not-allowed disabled:bg-gray-500"
              disabled={submitting}
            >
              {t("reset-password-button")}
            </button>
          </form>
        </>
      )}
    </PreAuthContent>
  );
}
