import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BankIdLogin from "./BankIdLogin";
import Button from "./Button";
import PreAuthContent from "./PreAuthContent";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";

const bankIdLogo = new URL("../assets/images/bankid-dark.svg", import.meta.url);

export default function SignInSwitch({
  title,
  backUrl,
  loginMode = "email-password",
}: {
  title: string;
  backUrl?: string;
  loginMode?: string;
}) {
  const [display, setDisplay] = useState("default");
  const { t } = useTranslation();

  document.title = t("signin-title");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (
      searchParams.has("prs") ||
      searchParams.has("as") ||
      searchParams.has("email")
    ) {
      setDisplay("email-password");
    }
  }, []);

  const renderBankIdButton = () => {
    return (
      <Button
        onClick={() => setDisplay("bankid")}
        title="bankid"
        cssClasses=""
        rightArrow={false}
        theme="yellow"
        iconComp={
          <img className="relative" alt="" src={bankIdLogo.href} width={30} />
        }
        icon={{ width: -15 }}
      />
    );
  };

  const renderSignInButton = () => {
    return (
      <div
        className="text-white uppercase font-intermedium leading-tight tracking-widest lg:leading-none text-[12px] cursor-pointer"
        onClick={() => setDisplay(loginMode)}
      >
        e-post och Lösenord
      </div>
    );
  };

  const renderContent = () => {
    switch (display) {
      case "bankid":
        return <BankIdLogin backUrl={backUrl} />;
      case "email-password":
        return <SignInForm />;
      case "password":
        return <SignUpForm />;
      default:
        return (
          <div className="mx-auto relative flex flex-col gap-5 items-center justify-center">
            {renderBankIdButton()}
            {renderSignInButton()}
          </div>
        );
    }
  };

  return (
    <PreAuthContent title={title} noBg={display === "default"}>
      {renderContent()}
    </PreAuthContent>
  );
}
