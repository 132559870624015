import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import LoaderScreen from "../components/LoaderScreen";
import { useAuth } from "../context/AuthContext";

export function RequireAuth({ children }) {
  const auth = useAuth();
  const loc = useLocation();

  useEffect(() => {
    auth.validateAuth();
  }, []);

  if (auth.user === null) {
    return (
      <>
        <LoaderScreen />
        <Navigate to="/signin" state={{ from: loc }} replace />
      </>
    );
  }

  return children;
}
