import React from "react";
import { useTranslation } from "react-i18next";

import SignInSwitch from "../components/SignInSwitch";

export default function SignUpPage() {
  const { t } = useTranslation();

  const title = t("signup-title");
  document.title = title;

  console.log("loc", location.pathname + location.search);

  return (
    <SignInSwitch
      title={title}
      loginMode="password"
      backUrl={location.pathname + location.search}
    />
  );
}
