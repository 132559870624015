import moment from "moment";
import React, { useLayoutEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { useAuth } from "../context/AuthContext";
import ToDoNestedList from "./ToDoNestedList";

let arrowIconDark = new URL(
  "../assets/images/todo-arrow-dark.svg",
  import.meta.url
);
let arrowIcon = new URL("../assets/images/todo-arrow.svg", import.meta.url);

let iconUser = new URL("../assets/images/icon-user.svg", import.meta.url);
let iconUserDark = new URL(
  "../assets/images/icon-user-dark.svg",
  import.meta.url
);

let iconCircle = new URL("../assets/images/circle-dark.svg", import.meta.url);
let iconCircleChecked = new URL(
  "../assets/images/circle-checked-white.svg",
  import.meta.url
);
let iconCircleExclamation = new URL(
  "../assets/images/circle-exc.svg",
  import.meta.url
);

const now = moment();

export default function ToDoItem({
  id,
  title,
  content,
  date,
  author,
  info,
  list,
  completed,
  toggleCompleted,
}) {
  const [expand, setExpand] = useState(false);
  const [height, setHeight] = useState();
  const ref = useRef(null);
  const auth = useAuth();

  useLayoutEffect(() => {
    setHeight(ref.current?.clientHeight);
  }, []);

  const defaultClasses = ["overflow-hidden", "transition-[max-height]"];
  const transitionStyles = {
    entering: { maxHeight: `${height}px` },
    entered: { maxHeight: `${height}px` },
    exiting: { maxHeight: 0 },
    exited: { maxHeight: 0 },
  };

  function toggleExpand() {
    setExpand(!expand);
  }

  function getCheckboxIcon() {
    if (completed) {
      return iconCircleChecked;
    } else if (date && date.diff(now, "days") < 0) {
      return iconCircleExclamation;
    } else {
      return iconCircle;
    }
  }

  function getAuthorIcon() {
    if (!author) {
      return null;
    }
    const icon = completed ? iconUserDark : iconUser;

    return (
      <div className="flex items-center">
        <span className={`mr-2 text-sm ${textColor}`}>You</span>
        <img
          className={`${iconBgColor} rounded-full p-1`}
          src={icon}
          width="25"
          height="25"
        />
      </div>
    );
  }

  function readMore() {
    if (!info) {
      return null;
    }
    return (
      <div className="flex items-center">
        <a
          href="https://google.com/"
          target="_blank"
          className={`mr-2 text-sm ${textColor} hover:underline`}
          rel="noreferrer"
        >
          More info
        </a>
      </div>
    );
  }

  function getArrowIcon() {
    if (completed) {
      return arrowIcon;
    } else {
      return arrowIconDark;
    }
  }

  let bgColor = "bg-white";
  let iconBgColor = "bg-dark-blue";
  let textColor = "text-dark-blue";
  if (completed) {
    bgColor = "bg-dark-blue";
    iconBgColor = "bg-white";
    textColor = "text-white";
  }

  const hasNestedItems = list?.length > 0;
  const hasContent = content && content.length > 0;
  const shouldExpand = hasNestedItems || hasContent;

  return (
    <div
      className={`relative shadow-default ${bgColor} rounded-lg px-5 py-4 mb-5`}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center flex-1">
          <img
            className={`mr-3 w-[25px] h-[25px] ${
              !hasNestedItems ? "hover:cursor-pointer" : ""
            }`}
            onClick={() => (!hasNestedItems ? toggleCompleted(id) : null)}
            src={getCheckboxIcon()}
            width="25"
            height="25"
          />
          <div
            className={`flex-grow ${
              shouldExpand ? "hover:cursor-pointer" : ""
            }`}
            onClick={() => toggleExpand()}
          >
            <div className={`font-interbold ${textColor} leading-5 mb-1`}>
              {title}
            </div>
            {date && (
              <div
                className={`text-[10px] leading-none first-letter:uppercase ${textColor}`}
              >
                {date.format("MMMM D, YYYY")}
              </div>
            )}
          </div>
          {readMore()}
          {getAuthorIcon()}
        </div>
        {shouldExpand ? (
          <div
            className={`p-1 transition hover:cursor-pointer ${
              expand ? "rotate-0" : "rotate-180"
            }`}
            onClick={() => toggleExpand()}
          >
            <img src={getArrowIcon()} width="25" height="25" />
          </div>
        ) : null}
      </div>
      <CSSTransition in={expand} timeout={150} nodeRef={ref}>
        {(state) => (
          <div
            className={defaultClasses.join(" ")}
            style={{ ...transitionStyles[state] }}
          >
            {shouldExpand ? (
              <div className={`pt-5 pb-3 ${textColor}`} ref={ref}>
                {content && content}
                {hasNestedItems && (
                  <ToDoNestedList
                    parentCompleted={completed}
                    parentId={id}
                    list={list}
                    toggleCompleted={toggleCompleted}
                  />
                )}
              </div>
            ) : null}
          </div>
        )}
      </CSSTransition>
    </div>
  );
}
