{
    "description": {
        "ida": "Data From Ida"
    },
    "messages": {
        "general": "Något gick fel.",
        "required-fields-empty": "Du måste fylla i det här fältet.",
        "passwordcannotbeempty": "Du måste fylla i det här fältet.",
        "passwords-match-error": "Lösenorden matchar inte. Försök igen.",
        "code-error": "Ange koden som du fått via mail.",
        "incorrectusernameorpassword": "Ogiltigt användarnamn eller lösenord.",
        "userdoesnotexist": "Ogiltigt användarnamn eller lösenord.",
        "cognitopassworddidnotconform": "Ogiltigt lösenord. Lösenordet måste bestå av minst 8 tecken, varav minst en en siffra och ett specialtecken. Använd både stora och små bokstäver.",
        "1validationerrordetectedvalueatpasswordfailedtosatisfyconstraintmembermustsatisfyregularexpressionpattern^[\\s]+*[\\s]+$": "Ogiltigt lösenord. Lösenordet måste bestå av minst 8 tecken, varav minst en en siffra och ett specialtecken. Använd både stora och små bokstäver.",
        "passworddoesnotconformtopolicypasswordnotlongenough": "Ogiltigt lösenord. Lösenordet måste bestå av minst 8 tecken, varav minst en en siffra och ett specialtecken. Använd både stora och små bokstäver.",
        "passworddoesnotconformtopolicypasswordmusthaveuppercasecharacters": "Ogiltigt lösenord. Lösenordet måste bestå av minst 8 tecken, varav minst en en siffra och ett specialtecken. Använd både stora och små bokstäver.",
        "LimitExceededException": "Försöksgränsen har överskridits, försök efter en tid.",
        "UserNotFoundException": "Ogiltigt e-postadress. Det måste vara samma e-postadress som du angivit för ditt konto hos oss.",
        "NotAuthorizedException": "Ogiltigt e-postadress. Det måste vara samma e-postadress som du angivit för ditt konto hos oss.",
        "duharintetillgångtillnågonbeställning": "Du har inte tillgång till någon beställning."
    },
    "homepage-title": "Home",
    "homepage-no-auth": "Sign In to view the content.",
    "signin-title": "Logga in",
    "signin-bankid-title": "Logga in med BankId",
    "signin-button": "Logga in",
    "signup-title": "Skapa användare",
    "signup-button": "Skapa användare",
    "reset-password-title": "Glömt ditt lösenord?",
    "reset-password-button": "Återställ lösenord",
    "reset-password-text": "Fyll i din e-postadress så hjälper vi dig.",
    "reset-password-title-success": "Återställ lösenord",
    "reset-password-confirm-title": "Bekräfta ändring av lösenord",
    "reset-password-confirm-code": "Säkerhetskod",
    "reset-password-confirm-code-placeholder": "Ange koden som du fått via mail",
    "reset-password-confirm-button": "Bekräfta",
    "activate-title": "Aktivera konto",
    "activate-success": "Ditt lösenord är nu ändrat. Välkommen att logga in igen.",
    "activate-desc": "Du har nu fått ett mejl av oss. Följ instruktionerna i mejlet för att aktivera ditt personliga Fenix-konto.",
    "email": "E-post",
    "email-placeholder": "Fyll i din e-postadress här",
    "shared-email-placeholder": "Fyll i e-postadress här",
    "name": "Namn",
    "first-name": "Förnamn",
    "first-name-placeholder": "Skriv in förnamn här",
    "last-name": "Efternamn",
    "last-name-placeholder": "Skriv in efternamn här",
    "password": "Lösenord",
    "password-placeholder": "Ange ditt lösenord här",
    "password-create": "Välj ett lösenord",
    "password-create-placeholder": "Ange ditt lösenord här",
    "password-verify": "Upprepa lösenordet för att bekräfta",
    "password-verify-placeholder": "Ange ditt lösenord här",
    "submit": "Submit",
    "no-items": "Inga objekt att visa",
    "sign-out": "Logga ut",
    "book-a-meeting": "Boka möte",
    "forgot-password-title": "Har du glömt ditt lösenord?",
    "forgot-password-desc": "Kolla din mail. Du har fått en länk för återställning av lösenord. ",
    "forgot-password-success": "Ditt lösenord är nu ändrat. Välkommen att logga in igen.",
    "profile-title": "Profile",
    "profile-remove-button": "Remove Cognito User & Sign Out",
    "todo-menu-title": "Att göra-lista",
    "todo-title": "{{name}}s begravning. Steg för steg.",
    "todo-title-no-name": "Begravning. Steg för steg.",
    "todo-description": "Det är viktigt för oss att du känner dig trygg under hela processen. Här får du en överblick över planeringen – vad som är klart och vad som är kvar att göra. Självklart finns vi alltid nära till hands om du har frågor eller funderingar.",
    "todo-done": "Klart",
    "todo-this-week": "Att göra den här veckan",
    "todo-next-week": "Att göra nästa vecka",
    "todo-later": "Att göra senare ",
    "enter-text": "Skriv här",
    "bookmeeting-title": "Din begravningsrådgivare på Fenix",
    "bookmeeting-title-prefix": "Boka möte med {{name}}",
    "communication-title": "Konversation",
    "messenger-no-messages": "Du har ingen kommunikation än",
    "files-menu-title": "Dokument",
    "files-title": "Alla viktiga papper på ett ställe.",
    "files-description": "För att göra det så enkelt för dig som möjligt, samlar vi alla dokument i samband med begravningen här. Vi vet av erfarenhet att det kan vara skönt att ha allt på ett och samma ställe.",
    "images-menu-title": "Foton",
    "images-title": "Foton från ceremonin.",
    "images-description": "Här hittar du alla bilder från fotografering av kondoleanser, blommor och minnesgåvor i samband med begravningen. Foton är ett fint sätt att minnas och gör det också lättare för dig att komma ihåg om du vill skicka ett tackkort efteråt.",
    "funeral-director": "Begravningsrådgivare",
    "send": "skicka",
    "visit-memorial-site": "Besök minnessida",
    "shared-with-title": "Delad med",
    "shared-success-title": "Din inbjudan är skickad",
    "shared-success-text": "Som inbjuden användare får man ta del av att-göra-listan och konversationen. Man får även tillgång till alla dokument och bilder. Däremot kan man inte själv fylla i något eller göra några ändringar.",
    "invite": "Inbjudan",
    "send-invite": "Skicka inbjudan",
    "show-more": "Visa mer",
    "show-less": "Visa mindre",
    "and": "och",
    "you": "dig",
    "more": "till",
    "cost": "Kostnad",
    "no-submit": {
        "default": "Du kan inte skicka eller uppdatera information.",
        "messenger": "Som inbjuden användare kan du se allt som i konversationen, men du kan inte skriva något själv.",
        "bookmeeting": "Som inbjuden användare kan du inte boka möten.",
        "todo": "Som inbjuden användare kan du se allt som står i att-göra-listan, men du kan inte göra några ändringar. <br /><br /> Har du frågor eller funderingar är du alltid välkommen att kontakta oss på <a style='text-decoration:underline;' href='mailto:kund@fenixfamily.se'>kund@fenixfamily.se</a>. Du når oss även på <a style='text-decoration:underline;' href='tel:0101389446'>010-138 94 46</a>."
    },
    "no-order-access": "Du har inte tillgång till någon beställning."
}