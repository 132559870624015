import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";

import PasswordField from "../components/PasswordField";
import { useAuth } from "../context/AuthContext";
import { convertStrToParam } from "../services/utils";

export default function SignInForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useAuth();

  useEffect(() => {
    const prs = searchParams.get("prs");
    const as = searchParams.get("as");
    const em = searchParams.get("email");
    if (em) {
      setEmail(em);
    }
    if (prs) {
      auth.addNotification(t("forgot-password-success"), "success");
    }
    if (as) {
      auth.addNotification(t("activate-success"), "success");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (!email || !password) {
      auth.addNotification(t("messages.required-fields-empty"));
      setSubmitting(false);
      return;
    }

    const res = await auth.signIn(email, password);
    if (res?.error) {
      console.log("Error:", JSON.stringify(res.error), JSON.stringify(res));
      const p = convertStrToParam(res.error);
      auth.addNotification(t([`messages.${p}`, "messages.general"]));
    } else {
      // const from = location.state?.from?.pathname || "/";
      const from = "/todo";
      navigate(from, { replace: true });
    }

    setSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <fieldset className="mb-6">
        <label className="block mb-2.5 font-interbold">
          {t("email")} <span className="text-red-600">*</span>
        </label>
        <input
          className="border-2 border-dark-blue rounded-full py-1.5 px-4 w-full placeholder:text-sm"
          type="email"
          name="email"
          id="email"
          placeholder={t("email-placeholder")}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </fieldset>
      <fieldset className="mb-5">
        <div className="flex justify-between items-center mb-2.5">
          <label className="font-interbold">
            {t("password")} <span className="text-red-600">*</span>
          </label>
          <Link to="/forgot-password" className="text-sm">
            {t("forgot-password-title")}
          </Link>
        </div>
        <PasswordField
          name="password"
          value={password}
          placeholder={t("password-placeholder")}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </fieldset>
      <button
        type="submit"
        className="block bg-dark-blue rounded-full text-white font-interbold py-3 px-10 mt-10 mx-auto uppercase disabled:cursor-not-allowed disabled:bg-gray-500"
        disabled={submitting}
      >
        {t("signin-button")}
      </button>
    </form>
  );
}
