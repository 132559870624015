import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Header from "../components/Header";
import ToDoList from "../components/ToDoList";
import { useAuth } from "../context/AuthContext";
import { RequireAuth } from "../services/auth";
import { isDesktopView } from "../services/utils";

export default function TodoPage() {
  const [title, setTitle] = useState("");
  const [headerHeight, setHeaderHeight] = useState();
  const [wrapperStyle, setWrapperStyle] = useState();
  const auth = useAuth();
  const { t } = useTranslation();
  const headerRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    let pageTitle = t("todo-title-no-name");
    if (auth.user.memorial && auth.user.memorial?.firstName !== "") {
      pageTitle = t("todo-title", { name: auth.user.memorial.firstName });
    }

    document.title = pageTitle;
    setTitle(pageTitle);
  }, []);

  useEffect(() => {
    if (document.readyState === "complete") {
      const headerInterval = setInterval(() => {
        setHeaderHeight(headerRef.current?.clientHeight);
      }, 50);

      return () => clearInterval(headerInterval);
    }
  }, [document.readyState]);

  useEffect(() => {
    setWrapperStyle(st());
  }, [headerHeight]);

  const st = () => {
    if (headerHeight === undefined || !isDesktopView()) {
      return null;
    }
    return {
      minHeight: `calc(100vh - ${headerHeight}px)`,
      maxHeight: `calc(100vh - ${headerHeight}px)`,
    };
  };

  return (
    <RequireAuth>
      <div className="flex lg:block flex-col min-h-screen lg:min-h-fit min-w-full max-w-fit">
        <div ref={headerRef}>
          <Header
            title={title}
            desc={t("todo-description")}
            showMemorial={true}
          />
        </div>

        <div
          className="p-5 pb-[50px] lg:pt-8 lg:pb-10 sm:p-10 overflow-y-auto max-h-screen grow"
          style={wrapperStyle}
          ref={wrapperRef}
        >
          <ToDoList wrapper={wrapperRef} />
        </div>
      </div>
    </RequireAuth>
  );
}
