import { Amplify } from "aws-amplify";

const config = {
  aws_cognito_region: process.env.AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.AWS_COGNITO_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.AWS_COGNITO_USER_POOLS_WEB_CLIENT_ID,
};

Amplify.configure(config);

export default Amplify;
