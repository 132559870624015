import React, { useState } from "react";

let icon = new URL("../assets/images/show-password.svg", import.meta.url);

export default function PasswordField({
  name = "password",
  value,
  placeholder,
  onChange,
}) {
  const [showPass, setShowPass] = useState(false);

  const togglePassword = () => {
    setShowPass(!showPass);
  };

  return (
    <div className="relative">
      <input
        className="border-2 border-dark-blue rounded-full py-1.5 px-4 w-full placeholder:text-sm"
        type={showPass ? "text" : "password"}
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      <button
        className="absolute right-4 top-2"
        type="button"
        onClick={togglePassword}
      >
        <img
          className="opacity-100 hover:opacity-80 transition"
          src={icon.href}
          width="25"
          height="25"
          alt=""
        />
      </button>
    </div>
  );
}
