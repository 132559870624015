import React from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../context/AuthContext";
import Tooltip from "./Tooltip";

export default function WithSubmitRightsTooltip({
  children,
  placement = "top",
  label,
}) {
  const auth = useAuth();
  const { t } = useTranslation();

  if (auth.canSubmit() === false) {
    return (
      <Tooltip
        label={!label ? t("no-submit.default") : label}
        placement={placement}
      >
        {children}
      </Tooltip>
    );
  }

  return children;
}
