import React from "react";
import { useTranslation } from "react-i18next";

import SignInSwitch from "../components/SignInSwitch";

export default function SignInPage() {
  const { t } = useTranslation();

  const title = t("signin-title");
  document.title = title;

  return <SignInSwitch title={title} />;
}
