import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import PreAuthContent from "../components/PreAuthContent";
import { useAuth } from "../context/AuthContext";
import Amplify from "../services/amplify";
import { convertStrToParam } from "../services/utils";

export default function ForgotPasswordConfirmPage() {
  const [submitting, setSubmitting] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const auth = useAuth();

  document.title = t("reset-password-confirm-title");

  useEffect(() => {
    const email = searchParams.get("email");
    const code = searchParams.get("tempCode");
    setEmail(email);
    setCode(code);
  }, [searchParams]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (!code) {
      auth.addNotification(t("messages.code-error"));
      setSubmitting(false);
      return;
    }

    if (password !== passwordCheck) {
      auth.addNotification(t("messages.passwords-match-error"));
      setSubmitting(false);
      return;
    }

    await confirmChangePassword(email, code, password);
  };

  async function confirmChangePassword(email, code, password) {
    try {
      const data = await Amplify.Auth.forgotPasswordSubmit(
        email,
        code,
        password
      );

      // console.log(data);
      navigate("/signin?prs=1");
    } catch (error) {
      console.log("Error:", error);
      const p = convertStrToParam(error.message);
      auth.addNotification(t([`messages.${p}`, "messages.general"]));
    }

    setSubmitting(false);
  }

  return (
    <PreAuthContent title={t("reset-password-confirm-title")}>
      <form onSubmit={handleSubmit}>
        <fieldset className="mb-6">
          <label className="block mb-2.5 font-interbold">{t("email")}</label>
          <input
            className="border border-black focus:border-black bg-gray-100 text-gray-500 rounded-full py-1.5 px-4 w-full placeholder:text-sm cursor-not-allowed"
            type="text"
            name="email"
            id="email"
            placeholder={t("email-placeholder")}
            value={email}
            readOnly
            disabled
          />
        </fieldset>
        <fieldset className="mb-6">
          <label className="block mb-2.5 font-interbold">
            {t("reset-password-confirm-code")}{" "}
            <span className="text-red-600">*</span>
          </label>
          <input
            className="border border-black rounded-full py-1.5 px-4 w-full"
            type="text"
            name="code"
            id="code"
            placeholder={t("reset-password-confirm-code-placeholder")}
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
        </fieldset>
        <fieldset className="mb-6">
          <label className="block mb-2.5 font-interbold">
            {t("password")} <span className="text-red-600">*</span>
          </label>
          <input
            className="border border-black rounded-full py-1.5 px-4 w-full"
            type="password"
            name="password"
            id="password"
            placeholder={t("password-placeholder")}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </fieldset>
        <fieldset className="mb-5">
          <label className="block mb-2.5 font-interbold">
            {t("password-verify")} <span className="text-red-600">*</span>
          </label>
          <input
            className="border border-black rounded-full py-1.5 px-4 w-full"
            type="password"
            name="password-check"
            id="password-check"
            placeholder={t("password-verify-placeholder")}
            value={passwordCheck}
            onChange={(e) => {
              setPasswordCheck(e.target.value);
            }}
          />
        </fieldset>
        <button
          type="submit"
          className="block bg-dark-blue rounded-full text-white font-interbold py-3 px-10 mt-10 mx-auto uppercase disabled:cursor-not-allowed disabled:bg-gray-500"
          disabled={submitting}
        >
          {t("reset-password-confirm-button")}
        </button>
      </form>
    </PreAuthContent>
  );
}
