import * as Sentry from "@sentry/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../context/AuthContext";
import { useWsConnection } from "../context/WsContext";
import { getMessages, sendMessage } from "../services/ida";
import { isDesktopView } from "../services/utils";
import LoaderIcon from "./LoaderIcon";
import WithSubmitRightsTooltip from "./WithSubmitRightsTooltip";

const iconNoMessages = new URL(
  "../assets/images/no-messages.svg",
  import.meta.url
);
const iconNoMessagesWhite = new URL(
  "../assets/images/no-messages-white.svg",
  import.meta.url
);

export default function Messenger() {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgInputHeight, setMsgInputHeight] = useState(1);
  const [messages, setMessages] = useState();
  const auth = useAuth();
  const wsConnection = useWsConnection();
  const user = auth.user;
  const controllerRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (!user || !wsConnection || !wsConnection.connectionId) {
      return null;
    }

    const controller = fetchMessages();
    const handler = () =>
      setTimeout(() => {
        fetchMessages();
      }, 1000);

    wsConnection.on("Customer_Message", handler);

    return () => {
      if (typeof controller === "object") {
        controller.abort();
      }
      wsConnection.off("Customer_Message", handler);
    };
  }, [user, wsConnection?.connectionId]);

  function fetchMessages() {
    const orderId = user.customerIdaOrderId;
    if (orderId === undefined) {
      return null;
    }

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    getMessages(user, orderId, controllerRef)
      .then((data) => {
        if (data.success) {
          return data.object;
        }
        auth.addNotification(data.message);
      })
      .then((data) => {
        if (data && data.length > 0) {
          setMessages(data);
        } else {
          setMessages([]);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        auth.addNotification(error.message);
      });

    controllerRef.current = null;
    return controller;
  }

  const handleMessageChange = (e) => {
    const value = e.target.value;
    const countLines = [...value.matchAll("\n")].length;
    setMsg(value);
    setMsgInputHeight(countLines + 1);
  };

  const handleMessageSubmit = () => {
    if (auth.canSubmit() === false) {
      auth.addNotification(t("no-submit-access"));
      return false;
    }

    if (msg === "") {
      return false;
    }

    setLoadingSubmit(true);
    sendMessage(user, user.customerIdaOrderId, msg)
      .then(() => {
        fetchMessages();
        setLoadingSubmit(false);
        setMsg("");
        setMsgInputHeight(1);
      })
      .catch((error) => {
        setLoadingSubmit(false);
        Sentry.captureException(error);
        auth.addNotification(error.message);
      });
  };

  const renderMessages = () => {
    let wrapperClass = "";
    let userIconClass = "bg-white text-dark-blue";
    let msgClass = "text-white px-4";
    let msgRowClass = "bg-[#072b36]";
    let submitWrapperClass = "bg-white rounded-[30]";
    let submitInputClass = "py-3 px-5 bg-transparent";
    let submitButtonClass = "items-center m-1 max-w-[100px]";
    let noMsgTextClass = "text-white";
    let noMsgImgClass = "";
    let noMsgImg = iconNoMessagesWhite;
    if (isDesktopView()) {
      wrapperClass = "rounded-lg";
      userIconClass = "bg-dark-blue text-white";
      msgClass = "bg-white text-dark-blue px-5";
      msgRowClass = "bg-[#fafbfb]";
      submitWrapperClass = "m-3.5 mt-0 p-2";
      submitInputClass = "px-3 py-1 border-[#eaeaea] focus:border-[#eaeaea]";
      submitButtonClass = "max-w-[70px]";
      noMsgTextClass = "opacity-[0.5] text-dark-blue";
      noMsgImgClass = "opacity-[0.2]";
      noMsgImg = iconNoMessages;
    }
    return (
      <>
        <div
          className={`flex flex-col-reverse overflow-y-auto ${wrapperClass}`}
          style={{
            flex: "1 1 0%",
          }}
        >
          {messages === undefined && (
            <div className="flex flex-col items-center h-full justify-center">
              <LoaderIcon theme="dark" size="small" />
            </div>
          )}

          {messages !== undefined && messages.length === 0 && (
            <div className="flex flex-col items-center h-full grow justify-center">
              <img className={`mb-5 ${noMsgImgClass}`} src={noMsgImg} />
              <div
                className={`max-w-[200px] text-center font-interbold text-sm uppercase ${noMsgTextClass}`}
              >
                {t("messenger-no-messages")}
              </div>
            </div>
          )}

          {messages !== undefined &&
            messages.length > 0 &&
            messages.map((el, ind) => {
              let ic = null;
              let bg = "";

              if (ind % 2) {
                bg = msgRowClass;
              }

              let dir = "flex-row-reverse";
              let mr = "ml-3";

              if (el.senderType === 1) {
                dir = "flex-row";
                mr = "mr-3";
                // ic = iconUser2;
              }
              return (
                <div key={ind}>
                  <div
                    className={`flex items-center py-4 ${msgClass} ${dir} ${bg}`}
                  >
                    <div
                      className={`${mr} ${userIconClass} rounded-full font-pprightgothic w-[48] h-[48] flex shrink-0 justify-center items-center uppercase`}
                    >
                      {ic ? (
                        <img src={ic} width={48} height={48} />
                      ) : (
                        el.senderName.split(" ").reduce((acc, cur) => {
                          acc += cur.slice(0, 1);
                          return acc;
                        }, "")
                      )}
                    </div>
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {el.messageText}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <WithSubmitRightsTooltip label={t("no-submit.messenger")}>
          <div
            className={`${submitWrapperClass} flex items-end shadow-default rounded-[16] ${
              auth.canSubmit() === false ? "opacity-40" : ""
            }`}
          >
            <textarea
              className={`${submitInputClass} border-0 resize-none w-full focus:outline-none`}
              name="msg"
              id="msf"
              rows={msgInputHeight}
              placeholder={t("enter-text")}
              onChange={handleMessageChange}
              value={msg}
              disabled={auth.canSubmit() === false}
            />
            <button
              type="button"
              className={`${submitButtonClass} flex justify-center rounded-[16] text-white text-xs font-interbold py-3 xl:py-2 px-1 w-full uppercase bg-dark-blue`}
              onClick={handleMessageSubmit}
              disabled={auth.canSubmit() === false}
            >
              {loadingSubmit ? (
                <LoaderIcon theme="dark" size="tiny" />
              ) : (
                t("send")
              )}
            </button>
          </div>
        </WithSubmitRightsTooltip>
      </>
    );
  };

  return renderMessages();
}
