import React from "react";
import { useTranslation } from "react-i18next";

import ActiveMenuLink from "./ActiveMenuLink";

let iconToDo = new URL("../assets/images/icon-todo.svg", import.meta.url);
let iconToDoActive = new URL(
  "../assets/images/icon-todo-pink.svg",
  import.meta.url
);
let iconImages = new URL("../assets/images/icon-images.svg", import.meta.url);
let iconImagesActive = new URL(
  "../assets/images/icon-images-pink.svg",
  import.meta.url
);
let iconFiles = new URL("../assets/images/icon-files.svg", import.meta.url);
let iconFilesActive = new URL(
  "../assets/images/icon-files-pink.svg",
  import.meta.url
);

export default function MenuItems({ itemClass }) {
  const { t } = useTranslation();

  let menuItemClass = "";
  if (itemClass) {
    menuItemClass = itemClass;
  }

  return (
    <>
      <ActiveMenuLink to="/todo" image={iconToDo} imageActive={iconToDoActive}>
        <span className={menuItemClass}>{t("todo-menu-title")}</span>
      </ActiveMenuLink>
      <ActiveMenuLink
        to="/files"
        image={iconFiles}
        imageActive={iconFilesActive}
      >
        <span className={menuItemClass}>{t("files-menu-title")}</span>
      </ActiveMenuLink>
      <ActiveMenuLink
        to="/images"
        image={iconImages}
        imageActive={iconImagesActive}
      >
        <span className={menuItemClass}>{t("images-menu-title")}</span>
      </ActiveMenuLink>
    </>
  );
}
