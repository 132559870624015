import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAuth } from "../context/AuthContext";
import { isPortableView } from "../services/utils";
import Cost from "./Cost";
import Memorial from "./Memorial";
import MenuItems from "./MenuItems";
import Modal from "./Modal";
import UserMenuItems from "./UserMenuItems";

let logo = new URL("../assets/images/logo-white.svg", import.meta.url);
let menuIcon = new URL("../assets/images/menu.svg", import.meta.url);

export default function Header({ title, desc, showMemorial }) {
  const [menu, setMenu] = useState(false);
  const [smallHeader, setSmallHeader] = useState(false);
  const auth = useAuth();
  const { t } = useTranslation();
  const price = auth.user.total;

  useEffect(() => {
    setSmallHeader(isPortableView());
  }, []);

  const descClass = smallHeader
    ? "overflow-hidden text-ellipsis whitespace-nowrap md:whitespace-normal"
    : "";

  return (
    <div className="md:flex bg-dark-blue bg-auth bg-cover bg-no-repeat bg-center p-5 sm:p-10 drop-shadow-xl text-white relative z-[1]">
      <div className="grow">
        <div className="flex mb-6">
          <div className="mr-4 flex items-start shrink-0 sm:hidden">
            <Link to="/todo">
              <img src={logo} alt="Fenix" width="42" height="42" />
            </Link>
          </div>
          <h1 className="text-center flex-1 font-pprightgothic text-3xl sm:text-4xl md:text-5xl xl:text-6xl sm:text-left sm:flex-initial pt-[2px] sm:pt-auto">
            {title}
          </h1>
          <div className="ml-4 flex shrink-0 items-start sm:hidden">
            <img
              src={menuIcon}
              alt="Fenix"
              width="42"
              height="42"
              className="cursor-pointer"
              onClick={() => setMenu(!menu)}
            />
          </div>
        </div>
        {(showMemorial || price) && (
          <div className="block lg:hidden mb-5">
            {auth.user.memorial !== null ? (
              <Memorial {...auth.user.memorial} />
            ) : (
              ""
            )}
            {price ? (
              <div className="mt-5">
                <Cost price={price} />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {showMemorial && !auth.user.memorial && price && (
          <div className="hidden lg:block mb-5">
            <div className="mt-5">
              <Cost price={price} />
            </div>
          </div>
        )}
        <div className={`md:max-w-2xl ${descClass}`}>{desc}</div>
        {desc && desc.length > 0 && (
          <div className="flex justify-end md:hidden">
            <button
              type="button"
              className="text-sm font-interlight border-b-[1px]"
              onClick={() => setSmallHeader(!smallHeader)}
            >
              {smallHeader ? t("show-more") : t("show-less")}
            </button>
          </div>
        )}
      </div>
      {showMemorial && (
        <div className="hidden ml-5 lg:block">
          {auth.user.memorial !== null ? (
            <Memorial {...auth.user.memorial} />
          ) : (
            ""
          )}
          {auth.user.memorial && price ? (
            <div className="mt-5 text-center">
              <Cost price={price} />
            </div>
          ) : (
            ""
          )}
        </div>
      )}

      {menu && (
        <Modal
          title="Menu"
          titleClass="font-pprightgothic text-4xl text-white text-center"
          showLogo={true}
          onClose={() => setMenu(!menu)}
        >
          <div className="flex flex-col sm:w-[50%] mx-auto justify-between h-full">
            <div>
              <MenuItems itemClass="w-auto ml-2.5" />
            </div>
            <div>
              <UserMenuItems itemClass="w-auto ml-2.5" />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
