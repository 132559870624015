export default function PreAuthContent({ children, title, noBg = false }) {
  let classes = noBg ? "" : "max-w-[28rem] bg-white rounded";

  return (
    <div className="flex justify-center pt-[8%] relative">
      <div className={`w-[100%] p-8 mx-5 ${classes}`}>
        <h1
          className={`mb-8 text-center font-pprightgothic text-5xl sm:text-6xl ${
            noBg ? "text-white" : "text-dark-blue"
          } `}
        >
          {title}
        </h1>
        {children}
      </div>
    </div>
  );
}
