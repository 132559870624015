import React from "react";
import { Link } from "react-router-dom";

let closeIcon = new URL(
  "../assets/images/icon-close-dark.svg",
  import.meta.url
);

const ModalPopup = ({ title, children, onClose }) => {
  return (
    <div
      className="absolute z-50 flex w-full h-full justify-center items-start"
      style={{ backgroundColor: "rgba(0, 39, 51, .4)" }}
    >
      <div className="flex justify-center pt-[5%] max-h-[90%] w-full">
        <div className="flex flex-col max-w-[90%] w-auto p-8 mx-5 bg-[#fafbfb] rounded relative drop-shadow-xl">
          <div className="self-end mb-1">
            <img
              src={closeIcon}
              alt="close"
              width="20"
              height="20"
              className="h-fit max-w-none cursor-pointer"
              onClick={onClose}
            />
          </div>
          <h1 className="mb-6 text-center font-pprightgothic text-3xl sm:text-4xl md:text-5xl text-dark-blue px-8">
            {title}
          </h1>
          <div className="overflow-auto">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalPopup;
