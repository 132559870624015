import * as Sentry from "@sentry/react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../context/AuthContext";
import { useWsConnection } from "../context/WsContext";
import { getTodoItems, updateTodoItemStatus } from "../services/ida";
import { isDesktopView } from "../services/utils";
import LoaderIcon from "./LoaderIcon";
import Modal from "./Modal";
import ToDoSection from "./ToDoSection";

const now = moment();

const categories = {
  1: "Förberedelser personligt avsked",
  2: "Förberedelser ceremoni",
  3: "Förberedelser minnesstund",
  4: "Hantering av urna",
};

export default function ToDoList({ wrapper }) {
  const [rawData, setRawData] = useState();
  const [data, setData] = useState();
  const [showCanSubmitModal, setShowCanSubmitModal] = useState(false);
  const [initScroll, setInitScroll] = useState(0);
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth.user;
  const wsConnection = useWsConnection();
  const doneRef = useRef();
  const controllerRef = useRef();

  useEffect(() => {
    if (user) {
      fetchToDos(user);
    }
  }, [user]);

  useEffect(() => {
    if (!user || !wsConnection || !wsConnection.connectionId) {
      return null;
    }

    const handler = () =>
      setTimeout(() => {
        fetchToDos(user);
      }, 1000);

    wsConnection.on("Customer_Todo", handler);

    return () => {
      wsConnection.off("Customer_Todo", handler);
    };
  }, [user, wsConnection?.connectionId]);

  useEffect(() => {
    if (rawData) {
      processItems();
    }
  }, [rawData]);

  useEffect(() => {
    if (doneRef.current === undefined || initScroll === 1) {
      return null;
    }

    wrapper.current?.scrollTo({
      top: doneRef.current?.offsetTop + 25,
      left: 0,
      behavior: "smooth",
    });
    setInitScroll(1);
  }, [data]);

  function getItemCompletionStatus(todoStatus) {
    if (todoStatus === 2) {
      return 1;
    }
    return 0;
  }

  function processItems() {
    const items = rawData;
    let dataItems = [];

    for (const [key, value] of Object.entries(categories)) {
      dataItems.push({
        id: parseInt(key),
        title: value,
        completed: 0,
        list: [],
        info: 0,
        author: 0,
      });
    }

    items.map((item) => {
      const dt = item.deadline ? moment(item.deadline) : null;

      const obj = {
        id: item.id,
        title: item.hintText,
        completed: getItemCompletionStatus(item.todoStatus),
        order: item.rankUnderCategory,
        info: 0,
        author: 0,
        content: "",
        status: item.todoStatus,
        apiOrder: parseInt(item.rankOfCategory),
      };

      if (dt !== null) {
        obj.date = dt;
      }

      if (categories[item.toDoCategory]) {
        const index = dataItems.findIndex((el) => el.id === item.toDoCategory);
        dataItems[index].list.push(obj);
        dataItems[index].apiOrder = parseInt(item.rankOfCategory);
      } else {
        dataItems.push(obj);
      }
    });

    let dataItemsNew = dataItems.reduce((acc, item) => {
      if (item.list && item.list.length === 0) {
        return acc;
      }

      if (item.list && item.list.length > 0) {
        item.date = item.list.reduce((a, i) => {
          if (a === null && i.date) {
            return i.date;
          }

          return a && a.isAfter(i.date) ? i.date : a;
        }, null);

        item.list = item.list.sort((a, b) => {
          return a.order > b.order ? 1 : -1;
        });
        item.completed = calculateCategoryStatus(item);
      }

      acc.push(item);
      return acc;
    }, []);

    let dataItemsSorted = dataItemsNew.sort((a, b) => {
      return a.apiOrder > b.apiOrder ? 1 : -1;
    });

    setData(dataItemsSorted);
  }

  function calculateCategoryStatus(item) {
    let listItemsCompleted = [];

    item.list.map((i) => {
      if (i.completed === 1) {
        listItemsCompleted.push(i.id);
      }
    });

    return item.list.length === listItemsCompleted.length ? 1 : 0;
  }

  function fetchToDos(user) {
    if (!user) {
      return null;
    }
    const orderId = user.customerIdaOrderId;
    if (orderId === undefined) {
      return null;
    }

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    getTodoItems(user, orderId, controllerRef)
      .then((data) => {
        if (data.success) {
          return data.object;
        }
        auth.addNotification(data.message);
      })
      .then((data) => {
        if (!data) {
          return false;
        }
        if (data.length > 0) {
          setRawData(data);
        } else {
          setRawData([]);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        auth.addNotification(error.message);
      });

    controllerRef.current = null;

    return () => controller.abort();
  }

  function updateItem(user, todoId, todoStatus) {
    if (!todoId || !user) {
      return null;
    }

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    updateTodoItemStatus(user, todoId, todoStatus, controllerRef)
      .then((data) => {
        if (!data.success) {
          fetchToDos(user);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        auth.addNotification(error.message);
      });

    controllerRef.current = null;

    return () => controller.abort();
  }

  function toggleCompleted(id, nested = false) {
    if (auth.canSubmit() === false) {
      setShowCanSubmitModal(true);
      // auth.addNotification(t("no-submit.default"));
      return false;
    }
    const newItems = [...data];
    let itemStatus;
    let itemId;
    const newItemStatus = (itemStatus) => (itemStatus === 2 ? 1 : 2);

    if (nested) {
      const [mId, nId] = id.split(".");
      itemId = nId;
      const index = newItems.findIndex((el) => el.id == mId);
      const nestedIndex = newItems[index].list.findIndex(
        (el) => el.id == itemId
      );

      newItems[index].list[nestedIndex].completed =
        !newItems[index].list[nestedIndex].completed;

      itemStatus = newItems[index].list[nestedIndex].status;
      newItems[index].list[nestedIndex].status = newItemStatus(itemStatus);
      newItems[index].list[nestedIndex].completed = getItemCompletionStatus(
        newItems[index].list[nestedIndex].status
      );

      newItems[index].completed = calculateCategoryStatus(newItems[index]);
    } else {
      itemId = id;
      const index = newItems.findIndex((el) => el.id === id);
      itemStatus = newItems[index].status;
      newItems[index].status = newItemStatus(itemStatus);
      newItems[index].completed = getItemCompletionStatus(
        newItems[index].status
      );
    }

    updateItem(user, itemId, newItemStatus(itemStatus));

    setData(newItems);
  }

  function splitItems() {
    let itemsCompleted = [];
    let itemsThisWeek = [];
    let itemsNextWeek = [];
    let itemsLater = [];

    if (data === undefined) {
      return (
        <div className="flex flex-col items-center h-full justify-center">
          <LoaderIcon theme="dark" size="small" />
        </div>
      );
    }

    if (data.length === 0) {
      return <p>{t("no-items")}</p>;
    }

    data.map((item) => {
      if (item.completed) {
        itemsCompleted.push(item);
        return;
      }
      const dt = moment(item.date, "YYYY-MM-DD");
      const diff = dt.diff(now, "days");

      if (diff <= 7) {
        itemsThisWeek.push(item);
      } else if (diff > 7 && diff <= 14) {
        itemsNextWeek.push(item);
      } else {
        itemsLater.push(item);
      }
    });

    return (
      <>
        {itemsCompleted.length > 0 ? (
          <ToDoSection
            title={t("todo-done")}
            isDone={true}
            items={itemsCompleted}
            toggleCompleted={toggleCompleted}
            ref={doneRef}
          />
        ) : (
          ""
        )}
        {itemsThisWeek.length > 0 ? (
          <ToDoSection
            title={t("todo-this-week")}
            items={itemsThisWeek}
            toggleCompleted={toggleCompleted}
          />
        ) : (
          ""
        )}
        {itemsNextWeek.length > 0 ? (
          <ToDoSection
            title={t("todo-next-week")}
            items={itemsNextWeek}
            toggleCompleted={toggleCompleted}
          />
        ) : (
          ""
        )}
        {itemsLater.length > 0 ? (
          <ToDoSection
            title={t("todo-later")}
            items={itemsLater}
            toggleCompleted={toggleCompleted}
          />
        ) : (
          ""
        )}
      </>
    );
  }

  function createMarkup() {
    return { __html: t("no-submit.todo") };
  }

  return (
    <>
      {showCanSubmitModal && (
        <Modal onClose={() => setShowCanSubmitModal(false)}>
          <div
            className={`max-w-xl xl:min-w-[320px] ${
              !isDesktopView() ? "text-white" : ""
            }`}
            dangerouslySetInnerHTML={createMarkup()}
          />
        </Modal>
      )}
      {splitItems()}
    </>
  );
}
