import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import { generateRandomString } from "../services/utils";
import Notification from "./Notification";

let defaultDelay = 2000;

const NotificationList = ({ notifications, onClose }) => {
  const elRef = useRef(null);

  if (!elRef.current) {
    elRef.current = document.createElement("div");
  }

  useEffect(() => {
    const modalRoot = document.getElementById("notification");
    modalRoot.appendChild(elRef.current);
    return () => modalRoot.removeChild(elRef.current);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      closeList();
    }, defaultDelay);
    return () => clearTimeout(timer);
  });

  const closeList = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return notifications.length > 0
    ? createPortal(
        <div className="absolute w-full h-full flex flex-col items-center">
          <div className="mt-5 z-[60]">
            {notifications.map((item) => (
              <Notification
                key={generateRandomString()}
                {...item}
                onClose={closeList}
              />
            ))}
          </div>
        </div>,
        elRef.current
      )
    : null;
};

export default NotificationList;
