import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import defaultPhoto from "../assets/images/team.png";
import { useAuth } from "../context/AuthContext";
import { cleanPhone, isDesktopView } from "../services/utils";
import Modal from "./Modal";

export default function TeamCard() {
  const [modalWidth, setModalWidth] = useState(0);
  const [modalHeight, setModalHeight] = useState(0);
  const [bookModal, setBookModal] = useState(false);
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth.user;

  useEffect(() => {
    const w = window.innerWidth;
    const h = window.innerHeight;

    const width = Math.round(parseInt(w, 10));
    let iframeWidth = width <= 768 ? width : 768;
    let k = width <= 768 ? 1 : 0.8;

    setModalWidth(iframeWidth);
    setModalHeight(Math.round(parseInt(h, 10) * k));
  }, []);

  function getAcuityUrl() {
    const names = user.customerUserName.split(" ").reduce((acc, cur) => {
      return [...acc, cur];
    }, []);

    const firstName = names[0];
    const lastName = names[1] ?? firstName;

    const params = new URLSearchParams({
      owner: 18650906,
      // appointmentType: 35757172,
      "field:11996332": user.customerIdaCaseId,
      "field:11996333": user.jwtToken,
      "field:11996329": "Telefonmöte",
      firstName: firstName,
      lastName: lastName,
      email: user.email,
    });

    if (user.assignedUser.acuityId) {
      params.set("calendarID", user.assignedUser.acuityId);
    }

    return `https://app.acuityscheduling.com/schedule.php?${params.toString()}`;
  }

  function getPhoto() {
    if (user.assignedUser.image) {
      return user.assignedUser.image;
    }
    return defaultPhoto;
  }

  const onModalShow = () => {
    if (auth.canSubmit() === false) {
      auth.addNotification(t("no-submit-access"));
      return false;
    }

    setBookModal(true);
  };

  const onModalClose = () => {
    setBookModal(false);
  };

  return (
    <>
      <div
        className={`flex flex-row rounded bg-white drop-shadow-xl ${
          auth.canSubmit() === false ? "opacity-40 pointer-events-none" : ""
        }`}
      >
        <div className="rounded-tl-lg rounded-bl-lg w-[160px] h-[242px] overflow-hidden flex flex-grow justify-center">
          <img className="h-full h-full w-full object-cover" src={getPhoto()} />
        </div>
        <div className="flex flex-col justify-between flex-grow text-center text-[#002733] p-4">
          <div>
            <div className="font-interbold mt-5 mb-1 text-center leading-tight">
              {user.assignedUser.name}
            </div>
            <div className="text-sm mb-4">{t("funeral-director")}</div>
            {user.assignedUser.phone && user.assignedUser.phone !== "" ? (
              <div className="text-sm mb-4 font-interbold">
                <a href={`tel:${cleanPhone(user.assignedUser.phone)}`}>
                  {user.assignedUser.phone}
                </a>
              </div>
            ) : null}
          </div>
          <button
            type="button"
            className="block rounded-full text-dark-blue text-xs font-interbold py-2 px-1 mt-1 w-full uppercase bg-light-blue"
            onClick={onModalShow}
            disabled={auth.canSubmit() === false}
          >
            {t("book-a-meeting")}
          </button>
        </div>
      </div>
      {bookModal && (
        <Modal
          title={t("bookmeeting-title-prefix", {
            name: user.assignedUser.name,
          })}
          onClose={onModalClose}
        >
          <iframe
            src={getAcuityUrl()}
            width={modalWidth}
            height={modalHeight}
            className={`mx-auto max-w-full max-h-full ${
              !isDesktopView() ? "bg-white p-5" : ""
            }`}
          />
        </Modal>
      )}
    </>
  );
}
