import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

type Props = {
  to: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  image: string;
  imageActive: string;
  children: React.ReactNode;
};

export default function ActiveMenuLink(props: Props): React.ReactNode {
  const { children, to, onClick, image, imageActive, ...rest } = props;
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  let cl = "";
  let img = image;
  if (match && to) {
    cl = "bg-[#00171f]";
    img = imageActive ?? image;
  }

  return (
    <Link
      to={to}
      onClick={onClick}
      className={`block text-white font-interbold hover:bg-[#00171f] py-4 px-5 mb-1 transition ${cl}`}
      {...rest}
    >
      <div className="flex items-center justify-center lg:justify-start group-hover:justify-start">
        {image ? (
          <img
            src={img}
            width="20"
            height="20"
            className="h-fit max-w-none lg:mr-2.5 group-hover:mr-2.5"
            alt=""
          />
        ) : (
          ""
        )}
        {children}
      </div>
    </Link>
  );
}
