import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LoaderScreen from "../components/LoaderScreen";
import NotificationList from "../components/NotificationList";
import Amplify from "../services/amplify";
import { getOrders } from "../services/ida";
import {
  getFromStorage,
  removeFromStorage,
  setInStorage,
  userKey,
} from "../services/storage";
import { dataDeepCopy } from "../services/utils";

const allowedOrderTypes = [1, 2, 257, 258];

export const AuthContext = React.createContext();

export function useAuth() {
  return React.useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    const localUser = getFromStorage(userKey, null);
    let data = null;
    if (localUser) {
      data = dataDeepCopy(localUser);

      // force fetch data from api
      if (!data.bankid) {
        data.accessTokenExpires = 0;
      }
    }
    setUser(data);
  }, []);

  useEffect(() => {
    if (user === undefined) {
      return false;
    }

    if (user === null || (user && Date.now() < user.accessTokenExpires)) {
      setLoading(false);
    }

    async function validate() {
      return await validateAuth();
    }
    validate()
      .then((res) => {
        if (user && res === false) {
          addNotification(t("no-order-access"));
          signOut();
        }
      })
      .catch((e) => {
        addNotification(t("no-order-access"));
        signOut();
      });

    if (user && user.accessLevel === 0) {
      addNotification(t("no-order-access"));
      signOut();
    }
  }, [user]);

  const signIn = async (email, password) => {
    try {
      const authUser = await Amplify.Auth.signIn(email, password);
      const user = {
        name: authUser.username,
        email: authUser.attributes.email,
        jwtToken: authUser.signInUserSession.idToken.jwtToken,
        refreshToken: authUser.signInUserSession.refreshToken.token,
        accessToken: authUser.signInUserSession.accessToken.jwtToken,
        accessTokenExpires:
          authUser.signInUserSession.accessToken.payload.exp * 1000,
        customerIdaAccountId:
          authUser.signInUserSession.idToken.payload.customerIdaAccountId,
        customerUserName:
          authUser.signInUserSession.idToken.payload.fenixUserName,
      };

      const res = await updateUser(user);
      if (res === false) {
        throw Error(t("no-order-access"));
      }
      return user;
    } catch (error) {
      Sentry.captureException(error);
      return { error: error.message };
    }
  };

  const signInBankId = async (authUser) => {
    try {
      const user = {
        name: `${authUser.firstName} ${authUser.lastName}`,
        email: "",
        jwtToken: authUser.accessToken,
        refreshToken: authUser.refreshToken,
        accessToken: authUser.accessToken,
        accessTokenExpires: authUser.expDate,
        customerIdaAccountId: authUser.customerIdaAccountId,
        customerUserName: `${authUser.firstName} ${authUser.lastName}`,
        bankid: true,
      };

      const res = await updateUser(user);
      if (res === false) {
        navigate("/signin");
        throw Error(t("no-order-access"));
      }
      return user;
    } catch (error) {
      Sentry.captureException(error);
      return { error: error.message };
    }
  };

  const signOut = () => {
    setUser(null);

    removeFromStorage(userKey);
    navigate("/");
  };

  const addNotification = (message, type) => {
    setNotifications((items) =>
      items.concat({
        message,
        type,
      })
    );
  };

  const updateUser = async (user) => {
    const ordersData = await getOrders(user)
      .then((data) => {
        if (data.object.length === 0) {
          return false;
        }

        const ord = data.object.filter((item) => {
          return allowedOrderTypes.includes(parseInt(item.orderType));
        });

        if (ord.length === 0) {
          return false;
        }

        const obj = ord[0];
        let memorial = null;
        if (obj.isMemorialPageExists) {
          memorial = {
            firstName: obj.deceasedPersonFirstName,
            lastName: obj.deceasedPersonLastName,
            url: obj.memorialPageUrl,
          };
        }

        let assignedUser = null;
        if (obj.assignedUser) {
          assignedUser = {
            name: obj.assignedUser.name,
            image: obj.assignedUser.profileImageThumbUrl,
            phone: obj.assignedUser.phoneNumber,
            // acuityId: 7092657,
            acuityId: obj.assignedUser.acuityUniqueId,
          };
        }

        return [
          obj.id,
          obj.caseId,
          memorial,
          obj.total,
          assignedUser,
          obj.accessLevel,
        ];
      })
      .catch((error) => {
        Sentry.captureException(error);
        addNotification(error.message);
      });

    if (!ordersData) {
      return false;
    }

    const [orderId, caseId, memorial, total, assignedUser, accessLevel] =
      ordersData;
    if (orderId) {
      user.customerIdaOrderId = orderId;
    }
    if (caseId) {
      user.customerIdaCaseId = caseId;
    }
    if (total) {
      user.total = total;
    }
    if (assignedUser) {
      user.assignedUser = assignedUser;
    }

    user.accessLevel = accessLevel;
    user.memorial = memorial;

    setUser(user);
    setInStorage(userKey, user);
    return true;
  };

  const validateAuth = async () => {
    if (!user) {
      return false;
    }

    if (Date.now() < user.accessTokenExpires) {
      return true;
    }

    // Access token has expired, try to update it
    const cognitoUser = await Amplify.Auth.currentAuthenticatedUser();
    const signInUserSession = await new Promise((success, failure) => {
      cognitoUser.refreshSession(
        cognitoUser.signInUserSession.refreshToken,
        function (err, session) {
          if (err) {
            failure(err);
          } else {
            success(session);
          }
        }
      );
    });

    const res = await updateUser({
      ...user,
      jwtToken: signInUserSession.idToken.jwtToken,
      accessTokenExpires: signInUserSession.accessToken.payload.exp * 1000,
    });
    return res ?? false;
  };

  const canSubmit = () => {
    if (user.accessLevel === 2) {
      return true;
    }
    return false;
  };

  const value = {
    user,
    signIn,
    signInBankId,
    signOut,
    validateAuth,
    addNotification,
    canSubmit,
  };

  if (loading === true) {
    return <LoaderScreen />;
  }

  return (
    <AuthContext.Provider value={value}>
      <NotificationList
        notifications={notifications}
        onClose={() => setNotifications([])}
      />
      {children}
    </AuthContext.Provider>
  );
};
