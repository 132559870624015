import { setTenant } from "../services/storage";

const defaultLang = "se";
const defaultTenant = "se";

const domainsSE = [process.env.DOMAIN_SE, "localhost"];
const domainsUK = [process.env.DOMAIN_UK];

export default {
  name: "langDetector",

  lookup(options) {
    const domain = location.hostname;

    if (domainsSE.includes(domain)) {
      setTenant("se");
      return "se";
    }

    if (domainsUK.includes(domain)) {
      setTenant("en");
      return "en";
    }

    setTenant(defaultTenant);
    return defaultLang;
  },
};
