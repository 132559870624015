import moment from "moment";
import React from "react";

import { useAuth } from "../context/AuthContext";

let iconCircleDark = new URL(
  "../assets/images/circle-dark.svg",
  import.meta.url
);
let iconCircleWhite = new URL(
  "../assets/images/circle-white.svg",
  import.meta.url
);
let iconCircleCheckedDark = new URL(
  "../assets/images/circle-checked-dark.svg",
  import.meta.url
);
let iconCircleCheckedWhite = new URL(
  "../assets/images/circle-checked-white.svg",
  import.meta.url
);
let iconCircleExclamation = new URL(
  "../assets/images/circle-exc.svg",
  import.meta.url
);

const now = moment();

export default function ToDoNestedList({
  list,
  parentCompleted,
  parentId,
  toggleCompleted,
}) {
  const auth = useAuth();

  function getCheckboxIcon(item) {
    const checked = item.completed;
    if (checked && !parentCompleted) {
      return iconCircleCheckedDark;
    } else if (checked && parentCompleted) {
      return iconCircleCheckedWhite;
    } else if (
      !parentCompleted &&
      item.date &&
      item.date.diff(now, "days") < 0
    ) {
      return iconCircleExclamation;
    } else if (!parentCompleted) {
      return iconCircleDark;
    } else {
      return iconCircleWhite;
    }
  }

  function isCompleted(item) {
    return !!item.completed;
  }

  let textColor = "text-dark-blue";
  if (parentCompleted) {
    textColor = "text-white";
  }

  return (
    <div className="md:pl-9">
      {list.map((item) => {
        return (
          <div key={item.id} className={`relative mb-2`}>
            <div className="flex flex-row justify-between items-center">
              <div
                className="flex flex-row flex-1"
                onClick={() => toggleCompleted(parentId + "." + item.id, true)}
              >
                <img
                  className="mr-3 w-[25px] h-[25px] hover:cursor-pointer"
                  src={getCheckboxIcon(item)}
                  width="25"
                  height="25"
                />
                <div
                  className={`flex-grow hover:cursor-pointer ${
                    isCompleted(item) ? "line-through" : ""
                  }`}
                >
                  <div className={`${textColor}`}>
                    {item.title}
                    {item.date ? (
                      <span className="text-[11px] pl-3 pt-1 text-gray-500 inline-block first-letter:uppercase">
                        {item.date.format("MMMM D, YYYY")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
