import * as store from "store";

export const tenantKey = "fnxTnt";
export const userKey = "fnxUser";

export function getFromStorage(key, optionalDefaultValue = undefined) {
  return store.get(key, optionalDefaultValue);
}

export function setInStorage(key, data) {
  return store.set(key, data);
}

export function removeFromStorage(key) {
  return store.remove(key);
}

export function setTenant(value) {
  return setInStorage(tenantKey, value);
}

export function getTenant() {
  return getFromStorage(tenantKey);
}
