import React from "react";
import { Link } from "react-router-dom";

let logo = new URL("../assets/images/logo-white.svg", import.meta.url);
let logoSign = new URL("../assets/images/logo-white-sign.svg", import.meta.url);

export default function HeaderAuth() {
  return (
    <div className="relative px-8 py-5">
      <Link to="/todo" className="flex">
        <div className="mr-4 flex">
          <img src={logo} alt="Fenix" width="42" height="42" />
        </div>
        <div className="flex items-center">
          <img src={logoSign} alt="Fenix" width="95" height="24" />
        </div>
      </Link>
    </div>
  );
}
