import "moment/locale/sv";

import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import ErrorBoundary from "./components/ErrorBoundary";
import Layout from "./components/Layout";
import LayoutAuth from "./components/LayoutAuth";
import NoPage from "./components/NoPage";
import { AuthProvider } from "./context/AuthContext";
import { WsProvider } from "./context/WsContext";
import ActivatePage from "./pages/ActivatePage";
import DebugPage from "./pages/DebugPage";
import FilesPage from "./pages/FilesPage";
import ForgotPasswordConfirmPage from "./pages/ForgotPasswordConfirmPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ImagesPage from "./pages/ImagesPage";
import IndexPage from "./pages/IndexPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import TodoPage from "./pages/TodoPage";

export default function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <WsProvider>
          <Suspense fallback="loading">
            <Routes>
              <Route element={<LayoutAuth />}>
                <Route path="/signin" element={<SignInPage />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/activate" element={<ActivatePage />} />
                <Route
                  path="/forgot-password"
                  element={<ForgotPasswordPage />}
                />
                <Route
                  path="/forgot-password-confirm"
                  element={<ForgotPasswordConfirmPage />}
                />
              </Route>
              <Route element={<Layout />}>
                <Route path="/debug" element={<DebugPage />} />
                <Route path="/images" element={<ImagesPage />} />
                <Route path="/files" element={<FilesPage />} />
                <Route path="/" element={<IndexPage />} />
              </Route>
              <Route element={<Layout rightCol={true} />}>
                <Route path="/todo" element={<TodoPage />} />
              </Route>
              <Route path="*" element={<NoPage />} />
            </Routes>
          </Suspense>
        </WsProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}
