import * as Sentry from "@sentry/react";
import React, { useLayoutEffect, useRef, useState } from "react";

import { useAuth } from "../context/AuthContext";
import { downloadDocument } from "../services/ida";
import LoaderIcon from "./LoaderIcon";
import Modal from "./Modal";
import PdfPreview from "./PdfPreview";

let downloadIcon = new URL(
  "../assets/images/icon-download.svg",
  import.meta.url
);
let previewIcon = new URL("../assets/images/icon-preview.svg", import.meta.url);

const imageTypes = ["image/png", "image/jpeg", "image/gif", "image/bmp"];
const fileTypes = ["application/pdf"];

export default function DocumentItem({
  id,
  documentTypeName: name,
  preview,
  freeTextNote,
  base64Content,
}) {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const auth = useAuth();
  const user = auth.user;

  const onDownload = () => {
    setDownloadLoading(true);
    const caseId = user.customerIdaCaseId;

    downloadDocument(user, caseId, id)
      .then((data) => {
        const link = document.createElement("a");
        link.href = data.object.base64Content;
        link.download = data.object.fileName;
        link.click();
        setDownloadLoading(false);
      })
      .catch((error) => {
        setDownloadLoading(false);
        Sentry.captureException(error);
        auth.addNotification(error.message);
      });
  };

  const onPreview = () => {
    setPreviewModal(true);
    setPreviewLoading(true);
    const caseId = user.customerIdaCaseId;

    downloadDocument(user, caseId, id)
      .then((data) => {
        setPreviewLoading(false);
        setPreviewFile({
          content: data.object.base64Content,
          type: data.object.contentType,
        });
      })
      .catch((error) => {
        setPreviewLoading(false);
        Sentry.captureException(error);
        auth.addNotification(error.message);
      });
  };

  const onPreviewClose = () => {
    setPreviewModal(!previewModal);
    setPreviewFile(null);
  };

  function renderFile() {
    if (imageTypes.includes(previewFile.type)) {
      return (
        <img
          src={previewFile.content}
          className="max-w-full max-h-full mx-auto"
        />
      );
    }
    if (fileTypes.includes(previewFile.type)) {
      return (
        <div className="flex justify-center">
          <PdfPreview file={previewFile.content} />
        </div>
      );
    }
  }

  return (
    <div className="flex flex-col relative rounded max-w-[160px] bg-white drop-shadow-xl">
      <div className="relative group">
        {base64Content ? (
          <div
            className="flex flex-shrink-0 rounded-t-lg mb-4 w-[160px] h-[109px] bg-no-repeat bg-cover bg-center cursor-pointer"
            style={{ backgroundImage: `url(${base64Content})` }}
          />
        ) : (
          <img className="rounded-t-lg mb-4 cursor-pointer" src={preview} />
        )}
        <div className="absolute hidden w-full h-full top-0 group-hover:block">
          <div
            className="flex h-full justify-end items-start cursor-pointer pt-2 pr-2"
            onClick={onPreview}
          >
            <img src={previewIcon} />
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between text-center text-[#002733] px-3">
        <div>
          <div className="font-interbold leading-5 mb-2">{name}</div>
          {/* <div className="mb-2 font-interbold uppercase">{extension}</div> */}
          <div className="px-0.5 mb-2 text-sm">{freeTextNote}</div>
        </div>
        <div className="flex justify-end mb-3 min-h-[24px]">
          {downloadLoading ? (
            <LoaderIcon theme="dark" size="small" />
          ) : (
            <button onClick={onDownload}>
              <img src={downloadIcon} />
            </button>
          )}
        </div>
      </div>
      {previewLoading && (
        <div className="absolute top-2 left-2">
          <LoaderIcon size="small" />
        </div>
      )}
      {!previewLoading && previewModal && (
        <Modal title={name} onClose={onPreviewClose}>
          {previewFile && renderFile()}
        </Modal>
      )}
    </div>
  );
}
