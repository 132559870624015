import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../context/AuthContext";
import { isDesktopView } from "../services/utils";
import Modal from "./Modal";
import TeamCard from "./TeamCard";
import WithSubmitRightsTooltip from "./WithSubmitRightsTooltip";

export default function BookMeeting() {
  const [modal, setModal] = useState(false);
  const auth = useAuth();
  const { t } = useTranslation();
  const title = t("bookmeeting-title");

  const diplayModal = () => {
    setModal(true);
  };

  const hideModal = () => {
    setModal(false);
  };

  if (!isDesktopView()) {
    return (
      <WithSubmitRightsTooltip label={t("no-submit.bookmeeting")}>
        <div>
          <button
            className={`rounded-full text-dark-blue text-sm font-interbold py-1 px-10 uppercase bg-white mx-9 ${
              auth.canSubmit() === false ? "opacity-40 pointer-events-none" : ""
            }`}
            onClick={diplayModal}
            disabled={auth.canSubmit() === false}
          >
            {t("book-a-meeting")}
          </button>
          {modal && (
            <Modal title={title} onClose={hideModal}>
              <div className="flex justify-center p-10">
                <TeamCard />;
              </div>
            </Modal>
          )}
        </div>
      </WithSubmitRightsTooltip>
    );
  }

  return (
    <div>
      <h3 className="text-xs uppercase text-dark-blue font-interbold mb-4">
        {title}
      </h3>
      <WithSubmitRightsTooltip
        placement="bottom"
        label={t("no-submit.bookmeeting")}
      >
        <div className="flex justify-center mb-8">
          <div
            className={`w-full relative ${
              auth.canSubmit() === false ? "opacity-40 pointer-events-none" : ""
            }`}
          >
            <TeamCard />
          </div>
        </div>
      </WithSubmitRightsTooltip>
    </div>
  );
}
