export function isDesktopView() {
  return window.innerWidth >= 1280;
}

export function isPortableView() {
  return window.innerWidth < 1024;
}

export function generateRandomString(length = 5) {
  return Math.random()
    .toString(36)
    .substr(2, length)
    .split("")
    .map((c) => (Math.random() < 0.5 ? c.toUpperCase() : c))
    .join("");
}

export function convertStrToParam(str) {
  const reg = RegExp(":|\\.|/|'|,", "g");
  return str.trim().split(" ").join("").toLowerCase().replaceAll(reg, "");
}

export function cleanPhone(phone) {
  const r = new RegExp("[\\s\\(\\)\\-]", "g");
  return phone.replaceAll(r, "");
}

export function dataDeepCopy(data) {
  return JSON.parse(JSON.stringify(data));
}

export const isFieldValid = (value, type = "text") => {
  const personNumberRegex = "(^\\d{12}$)|(^\\d{8}\\-\\d{4}$)";
  // const emailRegex = "^[a-z0-9._\\-\\+]{1,64}@[a-z0-9.]{1,64}$";
  const orgNumberRegex = "^(?:(\\d{10}){1}|(\\d{6}\\-\\d{4}){1})$";
  const emailRegex =
    "^[a-zA-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-z0-9](?:[a-zA-z0-9-]*[a-z0-9])?\\.)+[a-zA-z0-9](?:[a-zA-z0-9-]*[a-zA-z0-9])?$";
  const phoneNumberRegex = "^\\+{0,1}\\d{8,12}$";
  const personNumberBirthdayRegex = "^(\\d{8}|\\d{12})$";

  switch (type) {
    case "range":
      if (value === "") {
        return "Det här fältet måste du fylla i för att kunna gå vidare";
      }
      break;
    case "checkbox": {
      if (!value || value.length === 0) {
        return "Du måste välja ett alternativ för att kunna gå vidare";
      }
      break;
    }
    case "radio":
      if (value === undefined || value === "") {
        return "Du måste välja ett alternativ för att kunna gå vidare";
      }
      break;
    case "personnummer":
      if (value.match(personNumberRegex) === null) {
        return "Fyll i med 12 siffror, ÅÅÅÅMMDDXXXX";
      }
      break;
    case "orgnummer":
      if (value.match(orgNumberRegex) === null) {
        return "Fyll i med 10 siffror, ÅÅMMDDXXXX";
      }
      break;
    case "email":
      if (value.match(emailRegex) === null) {
        return "Det verkar vara fel e-postadress. Försök igen";
      }
      break;
    case "postcode":
      if (value === "" || value.length !== 5) {
        return "Fyll i med 5 siffror, XXXXX";
      }
      break;
    case "phone":
      if (value.match(phoneNumberRegex) === null) {
        return "Är det rätt telefonnummer? Försök igen";
      }
      break;
    case "birthday-personnummer":
      if (value.match(personNumberBirthdayRegex) === null) {
        return "Fyll i med personnummer med/utan 4 sista siffrorna";
      }
      break;
    case "text":
    default:
      if (value === "") {
        return "Det här fältet måste du fylla i för att kunna gå vidare";
      } else if (value.length < 2) {
        return "Du måste fylla i minst två tecken i fältet";
      }
  }

  return true;
};
