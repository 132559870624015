import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import trEN from "../locales/en/translation.json";
import trSE from "../locales/se/translation.json";
import langDetector from "./detector";

const resources = {
  en: { translation: trEN },
  se: { translation: trSE },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(langDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: ["langDetector"],
      lookupCookie: "fnxLng",
      lookupLocalStorage: "fnxLng",
      lookupSessionStorage: "fnxLng",
      fallbackLng: "se",
    },
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
