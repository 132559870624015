import React from "react";
import { useTranslation } from "react-i18next";

const flower = new URL("../assets/images/flower.svg", import.meta.url);

export default function Memorial({ url, firstName, lastName }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row lg:flex-col items-center rounded min-w-[160px] bg-dark-blue drop-shadow-xl break-words p-2 lg:p-0">
      <img
        className="rounded-t-lg lg:mt-3 lg:mb-2 lg:mx-auto w-[40] h-[40] lg:w-[72px] lg:h-[72px]"
        src={flower}
      />
      <div className="flex flex-row lg:flex-col h-full w-full items-center justify-between text-center text-white pl-3 lg:px-3">
        <div>
          <div className="font-interbold text-center leading-tight inline-block lg:block pr-4 lg:p-0 lg:mb-4">
            {firstName}&nbsp;
            <br className="hidden lg:block" />
            {lastName}
          </div>
        </div>
        <a href={url} target="_blank" rel="noreferrer">
          <button
            type="button"
            className="block rounded-full bg-white text-dark-blue text-xs font-interbold py-2 px-2 lg:mt-1 lg:mb-5 w-full uppercase"
          >
            {t("visit-memorial-site")}
          </button>
        </a>
      </div>
    </div>
  );
}
