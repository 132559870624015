import React, { useRef } from "react";
import { Outlet } from "react-router-dom";

import { useAuth } from "../context/AuthContext";
import { RequireAuth } from "../services/auth";
import { isDesktopView } from "../services/utils";
import BookMeeting from "./BookMeeting";
import MenuMain from "./MenuMain";
import MessengerDesktop from "./MessengerDesktop";
import MessengerMobile from "./MessengerMobile";
import ShareWith from "./ShareWith";

export default function Layout({ rightCol = false }) {
  const contRef = useRef();
  const shareRef = useRef();
  const meetingRef = useRef();

  const auth = useAuth();
  const user = auth.user;

  return (
    <RequireAuth>
      <div className="md:flex md:flex-row min-h-screen bg-[#fafbfb]">
        <div className="hidden sm:block relative bg-dark-blue lg:w-[180px] z-10">
          <MenuMain />
        </div>

        <div className="grow shrink basis-0 bg-white sm:ml-[66px] lg:ml-0">
          <Outlet />
        </div>

        {rightCol && isDesktopView() && (
          <div className="hidden shrink lg:block relative h-screen overflow-hidden bg-white ml-[16px]">
            <div
              ref={contRef}
              className="flex flex-col justify-between p-4 py-8 h-full w-[390px]"
            >
              {user && user.assignedUser ? (
                <div ref={meetingRef} className="flex flex-col">
                  <BookMeeting />
                </div>
              ) : null}

              <MessengerDesktop
                contRef={contRef}
                shareRef={shareRef}
                meetingRef={meetingRef}
              />
              <div ref={shareRef} className="flex flex-col min-h-[64px]">
                <ShareWith />
              </div>
            </div>
          </div>
        )}

        {rightCol && !isDesktopView() && (
          <div className="fixed flex justify-center items-center xl:hidden bg-dark-blue w-full bottom-0 text-white py-2">
            <ShareWith />
            {user && user.assignedUser ? <BookMeeting /> : null}
            <MessengerMobile />
          </div>
        )}
      </div>
    </RequireAuth>
  );
}
