import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import PreAuthContent from "../components/PreAuthContent";

const apiUrl = process.env.IDA_API_URL;

export default function ActivatePage() {
  const [actKey, setActKey] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  document.title = t("activate-title");

  useEffect(() => {
    const actKey = searchParams.get("activationKey");
    const email = searchParams.get("email");
    setActKey(actKey);
    setEmail(email);

    if (!actKey) {
      return false;
    }

    const fetchData = async () => {
      const url = `${apiUrl}/customer/activate/${actKey}`;

      const res = await fetch(url, {
        method: "PUT",
      });
      return await res.json();
    };

    fetchData().then((json) => {
      if (json.success === true) {
        navigate(`/signin?as=1&email=${email}`);
      }
    });
  }, [searchParams, navigate]);

  return (
    <PreAuthContent title={t("activate-title")}>
      {!actKey ? <p className="text-center">{t("activate-desc")}</p> : ""}
    </PreAuthContent>
  );
}
