import * as Sentry from "@sentry/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../context/AuthContext";
import { useWsConnection } from "../context/WsContext";
import { getDocuments } from "../services/ida";
import DocumentItem from "./DocumentItem";
import LoaderIcon from "./LoaderIcon";

let imgThumb = new URL("../assets/images/doc-pdf-thumb.svg", import.meta.url);
let pdfThumb = new URL(
  "../assets/images/doc-pdf-yellow-thumb.svg",
  import.meta.url
);

export default function DocumentList({ section, type }) {
  const [documents, setDocuments] = useState();
  const { t } = useTranslation();
  const auth = useAuth();
  const wsConnection = useWsConnection();
  const user = auth.user;
  const controllerRef = useRef();

  useEffect(() => {
    if (!user || !wsConnection || !wsConnection.connectionId) {
      return null;
    }

    const controller = fetchDocuments();

    let channel = "";
    switch (type) {
      case 1:
        channel = "Customer_Document";
        break;
      default:
      case 2:
        channel = "Customer_Image";
        break;
    }

    const handler = () =>
      setTimeout(() => {
        fetchDocuments();
      }, 1000);

    wsConnection.on(channel, handler);

    return () => {
      if (typeof controller === "object") {
        controller.abort();
      }
      wsConnection.off(channel, handler);
    };
  }, [user, wsConnection?.connectionId]);

  function fetchDocuments() {
    if (!user) {
      return null;
    }
    const caseId = user.customerIdaCaseId;
    if (caseId === undefined) {
      return null;
    }

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    getDocuments(user, caseId, type, controllerRef)
      .then((data) => {
        if (data.success) {
          return data.object;
        }
        auth.addNotification(data.message);
        return false;
      })
      .then((data) => {
        if (data instanceof Array && data.length > 0) {
          setDocuments(data);
          return true;
        }
        setDocuments([]);
      })
      .catch((error) => {
        Sentry.captureException(error);
        auth.addNotification(error.message);
      });

    controllerRef.current = null;
    return controller;
  }

  return (
    <>
      {documents === undefined && (
        <div className="flex flex-col items-center h-full justify-center">
          <LoaderIcon theme="dark" size="small" />
        </div>
      )}

      {documents instanceof Array && documents.length === 0 ? (
        <p className="text-sm text-dark-blue">{t("no-items")}</p>
      ) : null}

      {documents && documents.length > 0 ? (
        <div className="mb-10">
          {/* <h3 className="text-xs uppercase text-dark-blue font-interbold mb-4">
            {section}
          </h3> */}
          <div className="flex flex-wrap gap-8 max-w-fit">
            {documents.map((doc) => {
              return (
                <DocumentItem
                  preview={type === 2 ? pdfThumb : imgThumb}
                  key={doc.id}
                  {...doc}
                />
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
}
