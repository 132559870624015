import React from "react";

export const LocContext = React.createContext();

export function useLoc() {
  return React.useContext(LocContext);
}

export const LocProvider = ({ children, value }) => {
  return <LocContext.Provider value={value}>{children}</LocContext.Provider>;
};
