import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import { isDesktopView } from "../services/utils";
import ModalFullScreen from "./ModalFullScreen";
import ModalPopup from "./ModalPopup";

const Modal = (props) => {
  const elRef = useRef(null);
  if (!elRef.current) {
    elRef.current = document.createElement("div");
  }

  useEffect(() => {
    const modalRoot = document.getElementById("modal");
    modalRoot.appendChild(elRef.current);
    return () => modalRoot.removeChild(elRef.current);
  }, []);

  const ModalElement = isDesktopView() ? ModalPopup : ModalFullScreen;

  return createPortal(
    <ModalElement {...props}>{props.children}</ModalElement>,
    elRef.current
  );
};

export default Modal;
