import * as Sentry from "@sentry/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../context/AuthContext";
import { addSharedUser, getSharedUsers } from "../services/ida";
import { isDesktopView } from "../services/utils";
import LoaderIcon from "./LoaderIcon";
import Modal from "./Modal";
import WithSubmitRightsTooltip from "./WithSubmitRightsTooltip";

let icon = new URL("../assets/images/icon-share-with.svg", import.meta.url);

export default function ShareWith() {
  const [inviteFirstName, setInviteFirstName] = useState("");
  const [inviteLastName, setInviteLastName] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [sharedMembers, setSharedMembers] = useState();
  const { t } = useTranslation();
  const controllerRef = useRef();
  const auth = useAuth();
  const user = auth.user;

  useEffect(() => {
    if (!user) {
      return null;
    }
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    fetchSharedUsers(controllerRef);

    controllerRef.current = null;

    return () => controller.abort();
  }, []);

  function fetchSharedUsers(controllerRef) {
    const orderId = user.customerIdaOrderId;
    if (orderId === undefined) {
      return null;
    }

    getSharedUsers(user, orderId, controllerRef)
      .then((data) => {
        if (data.success) {
          return data.object;
        }
        if (user.accessLevel !== 1) {
          auth.addNotification(data.message);
        }
      })
      .then((data) => {
        if (data && data.length > 0) {
          setSharedMembers(data);
        } else {
          setSharedMembers([]);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        auth.addNotification(error.message);
      });
  }

  const onInviteShow = () => {
    if (auth.canSubmit() === false) {
      auth.addNotification(t("no-submit-access"));
      return false;
    }

    setInviteModal(true);
  };

  const onInviteClose = () => {
    setInviteModal(false);
    setSubmitted(false);
    clearForm();
  };

  const clearForm = () => {
    setInviteFirstName("");
    setInviteLastName("");
    setInviteEmail("");
  };

  const renderNames = () => {
    const you = t("you");
    let sufix = " ";
    const totalMembersLength = sharedMembers.length;
    if (totalMembersLength === 1) {
      sufix += `${t("and")} ${sharedMembers[0].name}`;
    } else if (totalMembersLength > 1) {
      sufix += `${t("and")} ${totalMembersLength} ${t("more")}`;
    }
    return you + sufix;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (!inviteFirstName || !inviteLastName || !inviteEmail) {
      auth.addNotification(t("messages.required-fields-empty"));
      setSubmitting(false);
      return;
    }

    const orderId = user.customerIdaOrderId;
    if (orderId === undefined) {
      setSubmitting(false);
      return;
    }

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();

    addSharedUser(
      user,
      orderId,
      {
        email: inviteEmail.trim(),
        firstName: inviteFirstName.trim(),
        lastName: inviteLastName.trim(),
      },
      controllerRef
    )
      .then((data) => {
        setSubmitting(false);

        if (data.success === false) {
          auth.addNotification(data.message);
          return false;
        }

        setSubmitted(true);
        fetchSharedUsers();
        clearForm();
      })
      .catch((error) => {
        setSubmitting(false);
        Sentry.captureException(error);
        auth.addNotification(error.message);
      });
  };

  const iconClasses =
    "bg-dark-blue rounded-full font-pprightgothic text-white w-[32] h-[32] flex justify-center items-center uppercase";

  let labelClass = "text-white";
  let formClass = "flex flex-col h-full justify-between";
  let inputClass = "";
  let sendButtonClass = "text-dark-blue bg-white";
  let successClass = "bg-dark-blue text-white";
  if (isDesktopView()) {
    labelClass = "text-black";
    formClass = "";
    inputClass = "border-2 border-dark-blue";
    sendButtonClass = "bg-dark-blue text-white";
    successClass = "bg-[#fafbfb] text-black";
  }

  return (
    <>
      <WithSubmitRightsTooltip>
        {isDesktopView() ? (
          <div>
            <h3 className="text-xs uppercase text-dark-blue font-interbold mb-4">
              {t("shared-with-title")}
            </h3>

            <div
              className={`flex flex-row w-full items-center justify-center ${
                auth.canSubmit() === false
                  ? "opacity-40 pointer-events-none"
                  : ""
              }`}
            >
              {sharedMembers === undefined ? (
                <div className="flex flex-col items-center h-full justify-center">
                  <LoaderIcon theme="dark" size="small" />
                </div>
              ) : (
                <>
                  <div className="flex shrink-0 mr-3">
                    <div className={`${iconClasses} z-10`}>
                      {user.customerUserName.split(" ").reduce((acc, cur) => {
                        acc += cur.slice(0, 1);
                        return acc;
                      }, "")}
                    </div>
                    {sharedMembers &&
                      sharedMembers.map((el, index) => {
                        const totalLength = sharedMembers.length;
                        return (
                          <div
                            key={el.email}
                            className={`${iconClasses} ml-[-13px]`}
                            style={{ zIndex: totalLength - index }}
                          >
                            {el.name.split(" ").reduce((acc, cur) => {
                              acc += cur.slice(0, 1);
                              return acc;
                            }, "")}
                          </div>
                        );
                      })}
                  </div>
                  <div className="grow text-sm font-interbold text-dark-blue uppercase">
                    {renderNames()}
                  </div>
                  <div className="shrink-0">
                    <button
                      type="button"
                      className="flex justify-center rounded-full text-dark-blue text-sm font-interbold py-1 px-5 w-full uppercase bg-white border-2 border-dark-blue"
                      onClick={onInviteShow}
                      disabled={auth.canSubmit() === false}
                    >
                      {t("invite")}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div>
            <img
              src={icon}
              onClick={onInviteShow}
              className={`h-fit max-w-none ${
                auth.canSubmit() === false
                  ? "opacity-40 pointer-events-none"
                  : "cursor-pointer"
              }`}
              width={31}
              height={18}
            />
          </div>
        )}
      </WithSubmitRightsTooltip>

      {inviteModal && (
        <Modal
          title={!submitted ? t("invite") : t("shared-success-title")}
          onClose={onInviteClose}
        >
          <form
            onSubmit={handleSubmit}
            className={`relative max-w-xl xl:min-w-[320px] mx-auto ${formClass}`}
          >
            <div>
              <fieldset className="mb-6">
                <label className={`block mb-2.5 font-interbold ${labelClass}`}>
                  {t("email")} <span className="text-red-600">*</span>
                </label>
                <input
                  className={`rounded-full py-1.5 px-4 w-full placeholder:text-sm ${inputClass}`}
                  type="email"
                  name="email"
                  id="email"
                  placeholder={t("shared-email-placeholder")}
                  value={inviteEmail}
                  required={true}
                  onChange={(e) => {
                    setInviteEmail(e.target.value);
                  }}
                />
              </fieldset>
              <fieldset className="mb-6">
                <label className={`block mb-2.5 font-interbold ${labelClass}`}>
                  {t("first-name")} <span className="text-red-600">*</span>
                </label>
                <input
                  className={`rounded-full py-1.5 px-4 w-full placeholder:text-sm ${inputClass}`}
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder={t("first-name-placeholder")}
                  value={inviteFirstName}
                  required={true}
                  onChange={(e) => {
                    setInviteFirstName(e.target.value);
                  }}
                />
              </fieldset>
              <fieldset className="mb-6">
                <label className={`block mb-2.5 font-interbold ${labelClass}`}>
                  {t("last-name")} <span className="text-red-600">*</span>
                </label>
                <input
                  className={`rounded-full py-1.5 px-4 w-full placeholder:text-sm ${inputClass}`}
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder={t("last-name-placeholder")}
                  value={inviteLastName}
                  required={true}
                  onChange={(e) => {
                    setInviteLastName(e.target.value);
                  }}
                />
              </fieldset>
              <button
                type="submit"
                className={`block rounded-full font-interbold py-3 px-10 mt-10 mx-auto uppercase disabled:cursor-not-allowed disabled:bg-gray-500 ${sendButtonClass}`}
                disabled={submitting}
              >
                {t("send-invite")}
              </button>
            </div>
            {submitted && (
              <div
                className={`${successClass} absolute left-0 top-0 w-full h-full text-lg`}
              >
                {t("shared-success-text")}
              </div>
            )}
          </form>
        </Modal>
      )}
    </>
  );
}
