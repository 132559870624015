let apiUrl = process.env.IDA_API_URL;

const tenantId = 1;

export async function getProfileInfo(accessToken) {
  try {
    const response = await fetch(
      `${apiUrl}/customer/profile?tenantId=${tenantId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      console.log(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
    }
    const data = await response.json();
    if (!data.object) {
      console.log("No data in response");
    }

    return data.object;
  } catch (error) {
    console.log(error);
  }
}

export async function getDataFromIda(user, controllerRef) {
  if (!user) {
    return false;
  }
  const res = await fetch(`${apiUrl}/customer/order`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.jwtToken}`,
    },
    signal: controllerRef.current?.signal,
  }).catch((error) => {
    console.error("Error: ", error);
    throw Error(error);
  });

  if (res?.ok) {
    return res.json();
  }

  throw Error("Unable to fetch data");
}

export async function getOrders(user) {
  if (!user) {
    return false;
  }
  const res = await fetch(`${apiUrl}/customer/order`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.jwtToken}`,
    },
  }).catch((error) => {
    console.error("Error: ", error);
    throw Error(error);
  });

  if (res?.ok) {
    return res.json();
  }

  throw Error("Unable to fetch data");
}

export async function getMessages(user, orderId, controllerRef) {
  if (!user || !orderId) {
    return false;
  }
  const res = await fetch(
    `${apiUrl}/customer/order/${orderId}/message?seen=true`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.jwtToken}`,
      },
      signal: controllerRef?.current?.signal,
    }
  ).catch((error) => {
    console.error("Error: ", error);
    throw Error(error);
  });

  if (res?.ok) {
    return res.json();
  }

  throw Error("Unable to fetch data");
}

export async function sendMessage(user, orderId, text) {
  if (!user || !orderId || !text) {
    return false;
  }
  const res = await fetch(`${apiUrl}/customer/order/${orderId}/message`, {
    method: "POST",
    body: JSON.stringify({ id: 0, messageText: text }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.jwtToken}`,
    },
  }).catch((error) => {
    console.error("Error: ", error);
    throw Error(error);
  });

  if (res?.ok) {
    return res.json();
  }

  throw Error("Unable to send data");
}

export async function getTodoItems(user, orderId, controllerRef) {
  if (!user || !orderId) {
    return false;
  }
  const res = await fetch(`${apiUrl}/customer/todo?orderId=${orderId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.jwtToken}`,
    },
    signal: controllerRef?.current?.signal,
  }).catch((error) => {
    console.error("Error: ", error);
    throw Error(error);
  });

  if (res?.ok) {
    return res.json();
  }

  throw Error("Unable to send data");
}

export async function updateTodoItemStatus(
  user,
  todoId,
  todoStatus,
  controllerRef
) {
  if (!user || !todoId) {
    return false;
  }
  const res = await fetch(
    `${apiUrl}/customer/todo/${todoId}?todoStatus=${todoStatus}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.jwtToken}`,
      },
      signal: controllerRef?.current?.signal,
    }
  ).catch((error) => {
    console.error("Error: ", error);
    throw Error(error);
  });

  if (res?.ok) {
    return res.json();
  }

  throw Error("Unable to send data");
}

export async function getDocuments(user, caseId, type, controllerRef) {
  if (!user || !caseId || !type) {
    return false;
  }
  const res = await fetch(
    `${apiUrl}/customer/case/${caseId}/document/type/${type}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.jwtToken}`,
      },
      signal: controllerRef?.current?.signal,
    }
  ).catch((error) => {
    console.error("Error: ", error);
    throw Error(error);
  });

  if (res?.ok) {
    return res.json();
  }

  throw Error("Unable to send data");
}

export async function downloadDocument(user, caseId, docId, controllerRef) {
  if (!user || !caseId || !docId) {
    return false;
  }
  const res = await fetch(
    `${apiUrl}/customer/${caseId}/document/${docId}/base64`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.jwtToken}`,
      },
      signal: controllerRef?.current?.signal,
    }
  ).catch((error) => {
    console.error("Error: ", error);
    throw Error(error);
  });

  if (res?.ok) {
    return res.json();
  }

  throw Error("Unable to get data");
}

export async function getSharedUsers(user, orderId, controllerRef) {
  if (!user || !orderId) {
    return false;
  }
  const res = await fetch(`${apiUrl}/customer/order/${orderId}/share`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user.jwtToken}`,
    },
    signal: controllerRef?.current?.signal,
  }).catch((error) => {
    console.error("Error: ", error);
    throw Error(error);
  });

  if (res?.ok) {
    return res.json();
  }

  throw Error("Unable to get data");
}

export async function addSharedUser(user, orderId, data, controllerRef) {
  if (!user || !orderId || !data) {
    return false;
  }
  const res = await fetch(`${apiUrl}/customer/order/${orderId}/share`, {
    method: "POST",
    body: JSON.stringify({
      FirstName: data.firstName,
      LastName: data.lastName,
      Email: data.email,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.jwtToken}`,
    },
    signal: controllerRef?.current?.signal,
  }).catch((error) => {
    console.error("Error: ", error);
    throw Error(error);
  });

  if (res?.ok) {
    return res.json();
  }

  throw Error("Unable to send data");
}
