import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Messenger from "./Messenger";
import Modal from "./Modal";

const iconMessages = new URL(
  "../assets/images/icon-messages.svg",
  import.meta.url
);

export default function MessengerMobile() {
  const [modal, setModal] = useState(false);

  const { t } = useTranslation();

  const title = t("communication-title");

  const displayModal = () => {
    setModal(true);
  };

  const hideModal = () => {
    setModal(false);
  };

  return (
    <>
      <img
        src={iconMessages}
        onClick={displayModal}
        className="cursor-pointer"
      />
      {modal && (
        <Modal title={title} onClose={hideModal}>
          <div
            className="flex flex-col justify-between"
            style={{ height: "calc(100vh - 118px)" }}
          >
            <Messenger />
          </div>
        </Modal>
      )}
    </>
  );
}
