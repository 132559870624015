import React, { PropsWithChildren } from "react";

const arrowWhite = new URL(
  "../assets/images/button-arrow-white.svg",
  import.meta.url
);
const arrowDark = new URL(
  "../assets/images/button-arrow-dark.svg",
  import.meta.url
);

type Props = PropsWithChildren<{
  onClick: () => void;
  title: string;
  type?: "button" | "submit" | "reset";
  theme?: string;
  id?: string;
  disabled?: boolean;
  size?: "small";
  cssClasses?: string;
  rightArrow?: boolean;
  hideIcon?: boolean;
  leftArrow?: boolean;
  icon?: { url?: URL; width: number };
  iconComp?: React.ReactNode;
}>;

export default function Button({
  onClick,
  title,
  theme,
  id,
  type = "button",
  disabled,
  size,
  cssClasses = "",
  rightArrow = true,
  leftArrow = false,
  hideIcon = false,
  icon,
  iconComp,
  children,
}: Props) {
  let themeClasses =
    "bg-dark-blue text-white border-dark-blue hover:opacity-80 w-full lg:w-auto";
  let sizeClasses = "text-[16px] py-[12px] lg:py-[20px] px-4 lg:px-9 lg:gap-4";
  let arrow = arrowWhite;
  let gap = "gap-3";
  let titleClasses =
    title.length > 25 && rightArrow ? "max-w-[58%] lg:max-w-none" : "";

  if (size === "small") {
    sizeClasses = "text-[10px] lg:text-[12px] border-[1px] py-2 px-3";
    gap = "gap-2";
  }

  if (theme === "yellow") {
    themeClasses =
      "bg-[#F7D152] border-[#F7D152] text-dark-blue hover:opacity-80";
    arrow = arrowDark;
  }

  if (theme === "white") {
    themeClasses = "bg-white border-dark-blue text-dark-blue hover:opacity-80";
    arrow = arrowDark;
    titleClasses = "";
    gap = "gap-2";
  }

  if (theme === "red") {
    themeClasses = "bg-[#EA3324] border-[#EA3324] text-white hover:opacity-80";
    arrow = arrowDark;
  }

  if (theme === "light-green") {
    themeClasses =
      "bg-theme-func-light-green border-theme-func-light-green text-theme-primary1 hover:opacity-80";
  }

  if (theme === "back-white") {
    themeClasses =
      "bg-theme-white border-transparent text-dark-blue underline hover:opacity-80";
    arrow = arrowDark;
  }

  if (disabled) {
    themeClasses += " !opacity-50";
  }

  return (
    <button
      id={id}
      type={type}
      onClick={onClick}
      className={`flex flex-row items-center justify-center border-[1px] font-intermedium uppercase transition ${sizeClasses} rounded-[5px] leading-tight tracking-widest lg:leading-none ${themeClasses} ${cssClasses} ${gap}`}
      disabled={disabled}
    >
      {leftArrow && (
        <img
          alt=""
          src={arrow.href}
          width={18}
          style={{ transform: "rotate(180deg)" }}
        />
      )}
      {(icon || iconComp) && !hideIcon ? (
        <>
          {iconComp && iconComp}

          {icon && !iconComp && (
            <img
              className="absolute left-[30px]"
              alt=""
              src={icon.url?.href}
              width={icon.width}
            />
          )}

          {icon && (
            <div style={{ marginLeft: `${icon.width + 20}px` }}>
              {title}
              {children}
            </div>
          )}
        </>
      ) : (
        <>
          <div className={titleClasses}>{title}</div>
          {children}
        </>
      )}
      {rightArrow && <img alt="" src={arrow.href} width={18} />}
    </button>
  );
}
