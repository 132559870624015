import * as signalR from "@microsoft/signalr";
import React, { useEffect, useState } from "react";

import { useAuth } from "./AuthContext";

export const WsContext = React.createContext();

const wsUrl = process.env.IDA_WS_URL;

export function useWsConnection() {
  return React.useContext(WsContext);
}

export function WsProvider({ children }) {
  const [connection, setConnection] = useState();
  const { user } = useAuth();

  useEffect(() => {
    if (user === undefined) {
      return false;
    }

    const conn = new signalR.HubConnectionBuilder()
      .withUrl(`${wsUrl}/notification/customer`)
      .configureLogging(signalR.LogLevel.Error)
      .build();

    if (user === null) {
      if (conn.connectionId !== null) {
        console.log("start ws disconnect");
        conn.stop().catch((error) => {
          console.log("Connection stop error = ", error);
        });
      }
      return;
    }

    if (user?.customerIdaAccountId && conn.connectionId === null) {
      conn
        .start()
        .then(() => {
          conn
            .invoke("RegisterUser", user.customerIdaAccountId)
            .catch((error) => {
              console.log("Connection invoke 'RegisterUser' error = ", error);
            });
        })
        .catch((e) => {
          console.log("Websocket connection failed", e);
        });

      setConnection(conn);
    }

    return () => {
      conn.stop();
    };
  }, [user]);

  return <WsContext.Provider value={connection}>{children}</WsContext.Provider>;
}
