import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App.js";
import { LocProvider } from "./context/LocContext";
import i18n from "./i18n/i18n";
import { getTenant } from "./services/storage";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    release:
      process.env.npm_package_name + "@" + process.env.npm_package_version,
  });
}

console.log(process.env.SENTRY_DSN);

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <LocProvider
        value={{
          lang: i18n.language,
          tenant: getTenant(),
          tenantId: getTenant() === "se" ? 1 : 2,
        }}
      >
        <App />
      </LocProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);
