import React from "react";
import { Link } from "react-router-dom";

import MenuItems from "./MenuItems";
import UserMenuItems from "./UserMenuItems";

let logo = new URL("../assets/images/logo-white.svg", import.meta.url);

export default function MenuMain() {
  const menuItemClass =
    "truncate w-0 leading-tight transition-all overflow-hidden lg:w-[120px] group-hover:w-[120px]";
  return (
    <div className="fixed group flex flex-col h-full bg-dark-blue lg:w-[180px]">
      <div className="flex pt-8 mb-5 mx-3 justify-center">
        <Link to="/todo">
          <img
            src={logo}
            alt="Fenix"
            width="42"
            height="42"
            className="h-fit max-w-none"
          />
        </Link>
      </div>

      <nav className="py-2.5 mb-5 flex-grow">
        <div className="flex flex-col">
          <MenuItems itemClass={menuItemClass} />
        </div>
      </nav>

      <UserMenuItems itemClass={menuItemClass} />
    </div>
  );
}
