import React from "react";
import { useTranslation } from "react-i18next";

export default function Cost({ price }) {
  const { t } = useTranslation();

  const priceFormatter = new Intl.NumberFormat("sv-SE", {
    style: "currency",
    currency: "SEK",

    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return (
    <div className="text-md">
      {t("cost")}:{" "}
      <span className="font-interbold">{priceFormatter.format(price)}</span>
    </div>
  );
}
